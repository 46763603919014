.export-btn {
    color: #660C60 !important;
    border: 1px solid #660C60 !important;
    border-radius: 6px !important;
    flex: 1;
}

.select-terms-div {
    padding: 30px;
}

.tab-body-cel-terms-cell {
    background-color: #F9F9F9 !important;
    padding: 5px 0px 5px 10px !important;
    border-bottom: 1px solid #EEEEEE !important;
}

.tab-body-cel-terms-cell-2 {
    background-color: #F9F9F9 !important;
    padding: 5px 0px 5px 35px !important;
}

.tbl-body-cell-3 {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
    background-color: #fff !important;
    border-bottom: 1px solid #EEEEEE !important;
}
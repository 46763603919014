.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: #f9f9f9;
}

.rows-per-page {
    display: flex;
    align-items: center;
    gap: 8px;
}

.label {
    font-size: 14px;
    color: #555;
}

.select-rows-per-page {
    min-width: 60px;
    border: 1px solid #eeeeee !important;
    border-radius: 4px !important;
}

.select-rows-per-page .MuiSelect-select {
    padding: 4px 8px;
    color: #555;
    border-bottom: none !important;
}

.select-rows-per-page::before {
    border-bottom: none !important;
}

.pagination-info {
    font-size: 14px;
    color: #555;
}

.pagination-buttons {
    display: flex;
    gap: 8px;
}

.pagination-buttons button {
    font-size: 12px;
    color: #660c60;
    border-radius: 50%;
}

@media (max-width: 420px) {
    .pagination {
        flex-direction: column;
        gap: 15px;
    }
}
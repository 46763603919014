.header-wrapper {
    padding: 23.5px 30px;
    border-bottom: 1px solid var(--border);
    z-index: 9;

    @media (max-width: 375px) {
        padding: 15px 15px;
    }

    .header-content {
        // max-width: 1920px;

        .btn {
            width: 111px;
        }
    }

    .drawer-div img {
        @media (max-width: 375px) {
            width: 105px !important;
        }
    }

    .drawer-div {
        @media (max-width: 481px) {
            gap: 0 !important;
        }
    }

    .create-content-btn {
        width: auto !important;
        background-color: #fb7900 !important;
        color: #ffff !important;
        border-color: #ffff !important;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .another-create-content-popup {
        // position: absolute !important;
        // top: 80px !important;
        background-color: #333333 !important;
    }

    .serach-box {
        max-width: 40.54%;
        flex: 0 0 40.54%;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .search-input {
        border: 1px solid #eeeeee;
        border-radius: 6px !important;
        background-color: #f9f9f9;
        height: auto;
        padding: 10px 54px 10px 15px;
    }

    .input-group>.form-control:focus,
    .input-group>.form-floating:focus-within,
    .input-group>.form-select:focus {
        z-index: 0 !important;
    }

    // .search-btn {
    //     background-color: #f9f9f9;
    //     border-color: #f9f9f9;
    //     width: 38px !important;
    //     height: 38px;
    //     border-radius: 100px !important;
    //     position: absolute !important;
    //     right: 0;
    //     margin: 4px 5px 4px 4px;
    // }
    .search-btn-global {
        background-color: #f9f9f9 !important;
        border-color: #f9f9f9 !important;
        width: 38px !important;
        height: 38px;
        border-radius: 100px !important;
        position: absolute !important;
        right: 0;
        margin: 4px 0px 4px 0px;
        top: 0px;
    }

    .search-btn-global:hover {
        background-color: none !important;
        border-color: none !important;
    }

    .search-btn-global svg {
        color: #660c60;
    }

    .search-btn svg {
        color: #660c60;
    }

    input.form-control::placeholder {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
    }

    .menu-icon {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.avatar-menu-icon-btn {
    margin-left: 0 !important;
    padding: 0px !important;
    color: #660C60 !important;
}

.notification-icon-btn {
    margin-left: 0 !important;
    padding: 0px !important;
    color: #660C60 !important;
    position: relative;

    @media (max-width: 481px) {
        display: none !important;
    }
}

.chat-icon-btn {
    margin-left: 0 !important;
    padding: 0px !important;
    color: #660C60 !important;
    position: relative;

    @media (max-width: 481px) {
        display: none !important;
    }
}

.plus-icon-btn {
    margin-left: 0 !important;
    padding: 0px !important;

    @media (min-width: 481px) {
        display: none !important;
    }
}

.avatar {
    width: 46px !important;
    height: 46px !important;
}

.last-stack {
    gap: 60px;

    @media (max-width: 1440.98px) {
        gap: 24px;
    }

    @media (max-width: 481px) {
        gap: 12px;
    }
}

.sidebar-dreawer-btn {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: #ffff !important;
}

.type-main-grid {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
}

.type-grid-list {
    padding-top: 0 !important;
    padding-left: 0 !important;
    max-width: 100% !important;
}

.type-list-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: space-between !important;
}

.main-box-class-2 {
    height: 110px;
    width: 124px;
    border-radius: 3px;
    padding: 29px 38px 29px 38px;
}

.main-app-icon-div {
    height: auto;
    width: 392px;

    @media (max-width: 481px) {
        width: 100%;
    }
}

.item-div {
    padding: 0 12px !important;
}

.main-app-icon-div-2 {
    height: auto;
    width: 263px;

    @media (max-width: 481px) {
        width: 100%;
    }
}

.menu-items-user {
    height: 37px;
    width: 253px;
}

.fs-20-660C60 {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #660c60 !important;
}

.fs-14-op-50 {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #333333 !important;
    opacity: 50%;
}

.react-switch-bg {
    height: 32px !important;
    width: 65px !important;
    margin: 0px !important;
    position: relative;
    background: #f9f9f9 !important;
    border-radius: 99px !important;
    border: 1px solid #eeeeee !important;
    cursor: pointer;
}

.react-switch-bg div {
    height: 100% !important;
}

.icon-switch {
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    color: #333333;
}

.main-switch-div {
    width: 65px;
    height: 32px;
    background-color: #f9f9f9;
    border-radius: 99px;
    padding: 4px;

    @media (max-width: 481px) {
        display: none;
    }
}

.switch-div-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.icon-div-2 {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-div-selected {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #660c60;
    border-radius: 99px;
}

.icon-div-selected svg path {
    color: #ffffff;
}

.users-chat-list-div {
    border-right: 1px solid #eeeeee;
    width: 45%;
    height: 548px;
    overflow: auto;
    background-color: #ffff;
}

.user-list {
    padding: 5px;
}

// .user-list {
//   max-height: 494px !important;
//   overflow: auto !important;
// }

.user-list-box {
    max-height: 494px;
    overflow: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }
}

.selected-chat-room-div {
    background-color: #f9f9f9 !important;
}

.user-list-items {
    align-items: center !important;
    justify-content: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.user-list-avatar-div {
    margin-top: 0 !important;
    min-width: 46px !important;
}

.chat-users-list {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.main-app-icon-div-3 {
    height: auto;
    width: 631px;
    // overflow: auto;
}

.user-chat-div {
    width: 55%;
}

.user-info-div {
    border-bottom: 1px solid #eeeeee;
}

.user-chats-div {
    height: 320px;
    border-bottom: 1px solid #eeeeee;
    overflow: auto;
}

#user-chats-div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Chat message styles */
.chat-message {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    // margin: 2px 0;
}

.chat-message.left {
    justify-content: flex-start;
    // background-color: #F9F9F9;
    // color: #1F2542;
    // padding: 10px;
    // border-radius: 12px;
    // width: max-content;
    max-width: 100%;
}

.chat-message.right {
    justify-content: flex-end;
    // background-color: #660C60;
    // color: #FFFFFF;
    // padding: 10px;
    // border-radius: 12px;
    // width: max-content;
    max-width: 100%;
}

.right .message-content {
    // max-width: 100%;
    word-wrap: break-word;
    // display: flex;
    // justify-content: flex-end;
    // background-color: #660c60;
    // color: #ffffff;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: max-content;
}

.left .message-content {
    // max-width: 100%;
    word-wrap: break-word;
    // display: flex;
    // justify-content: flex-end;
    // background-color: #f9f9f9;
    // color: #1f2542;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: max-content;
}

.create-content-popup .MuiPaper-elevation {
    /* top: 100px !important; */
    // top: 0 !important;
    // margin-top: 190px;
    max-height: 452px;
    height: 100%;
}

.chat-avatar {
    height: 32px !important;
    width: 32px !important;
}

.user-list-avatar {
    height: 32px !important;
    width: 32px !important;
}

.left .message-content {
    // max-width: 100%;
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            right: 0px;
            left: 0px;
            width: max-content;
        }
    }

    word-wrap: break-word;
    // display: flex;
    // justify-content: flex-end;
    // background-color: #f9f9f9;
    // color: #1f2542;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: 230px;
    // width: max-content;
}

.left .message-content .chat-msg-box-1 .reply-media-data-box-2 {
    padding: 10px;
    background-color: #F9F9F9 !important;
    border-radius: 12px;
}

.left .message-content .chat-msg-box-1 p {
    background-color: #F9F9F9 !important;
    color: #1F2542 !important;
    padding: 10px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border-radius: 0 0 12px 12px;
    width: max-content !important;
    max-width: 180px;
}

.right .message-content {
    // max-width: 100%;
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            right: 0px;
            width: max-content;
        }
    }

    // word-wrap: break-word;
    // display: flex;
    // justify-content: flex-end;
    // background-color: #660c60;
    // color: #ffffff;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: 230px;
    // width: max-content;
}

.right .message-content .chat-msg-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    gap: 4px;
}

.right .message-content .chat-msg-box-1 p {
    font-size: 14px !important;
    font-weight: 400 !important;
    white-space: pre-wrap;
    word-break: break-word;
    background: #660c60;
    color: #ffffff;
    padding: 10px;
    border-radius: 12px;
    max-width: 250px;
    width: max-content;
}

.message-content p {
    // font-size: 14px !important;
    // font-weight: 400 !important;
    // white-space: pre-wrap;
    // word-break: break-word;
    // background: #660c60;
    // color: #ffffff;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: 500px;
    // width: max-content;
}

.chat-badge .MuiBadge-badge {
    min-width: 18px !important;
    height: 18px !important;
    background-color: #f94144;
    border: 1px solid #ffffff;
    border-radius: 50px;
    font-size: 11px;
    padding: 0 !important;
    position: absolute;
    top: 20px;
    right: 12px;
}

.right .chat-data-con .MuiBox-root .MuiBox-root .message-time {
    display: flex;
    justify-content: flex-end;
}

.left .chat-data-con .MuiBox-root .MuiBox-root .message-time {
    display: flex;
    justify-content: flex-start;
}

.message-time {
    margin: 0 0 !important;
}

.message-time span {
    color: #777777 !important;
    font-size: 10.5px !important;
    font-weight: 600 !important;
}

.right .message-content p .url-link {
    color: white;
    font-size: 14px !important;
    font-weight: 400 !important;
    white-space: pre-wrap;
    word-break: break-word;
    background: #660c60;
    color: #ffffff;
    padding: 10px;
    border-radius: 12px;
    max-width: 500px;
    background: #660C60 !important;
    width: max-content;
}

.view-search-result-btn {
    background-color: #660c60 !important;
    color: #ffffff !important;
}

.global-search-popover {
    max-width: 754px;
    top: 100px !important;
    transform: translateX(-50%) !important;
    z-index: 9 !important;
    left: 50% !important;
    position: fixed !important;
}

.tab-box-2 .type-list-paper {
    padding: 0 15px 15px;
    max-height: 450px !important;
    overflow-y: auto;
}

.tab-box-2 .type-list-paper-global-search {
    padding: 0 15px 15px;
    max-height: calc(100vh - 265px);
    overflow-y: auto;
    box-shadow: none !important;
}

.tab-box-2 .type-list-paper .MuiTableContainer-root {
    overflow-x: clip !important;
}

.folder-tbl-cell {
    font-size: 14px !important;
    font-weight: 900 !important;
    line-height: 16px !important;
    color: #660c60 !important;
}

.folder-tbl-cell-2 {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.folder-tbl-cell-3 {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #660c60 !important;
}

.folder-tbl-cell-4 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.input-group-2 {
    padding: 0 16px !important;
}

.search-btn-dash-5 {
    background-color: #660c60 !important;
    border-color: #f9f9f9;
    width: 36px !important;
    height: 36px;
    border-radius: 100px !important;
    position: absolute !important;
    right: 0;
    margin: 4px 5px 4px 4px !important;
}

.serach-box .search-btn-dash {
    right: 20px;
}

.serach-box .search-btn-dash-5 {
    // margin: 4px !important;
    // bottom: 0px;
    // right: 2px;
}

.box-attribute {
    margin: 0 0 15px;
}

.global-search-tab-margin {
    margin-bottom: 0px !important;
}

.position-unset {
    position: unset !important;
}

.list-item-box {
    height: calc(100vh - 262px);
    overflow-y: auto;
}
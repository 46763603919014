.tbl-body-cell-creator {
    background-color: #fff !important;
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    padding: 14px 15px 14px 0px !important;
    border-bottom: 1px solid #EEEEEE !important;
}

.author-avtar-creator {
    height: auto !important;
    width: 32px !important;
    min-height: 32px;
    font-size: 14px !important;
}

.icon-div-user-group {
    height: 32px;
    width: 32px;
    border-radius: 99px;
    border: 1px solid #EEEEEE;
    cursor: pointer;
}

.icon-user-group {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 12px !important;
}

.managers {
    justify-content: start !important;
}

.type-list-first-header-group-name {
    width: 20% !important;
}

.type-list-second-header-group {
    width: 15% !important;
}
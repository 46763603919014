.col-insight-data {
    margin-top: 30px !important;
}

.col-insight-data-2 {
    margin-top: 20px !important;
}

.box-attribute {
    border-bottom: none !important;
}
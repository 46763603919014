.custom-avatar-group {
    display: flex;
    justify-content: flex-start;
}

.custom-avatar-group .MuiAvatar-root {
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease;
    width: 24px !important;
    height: 24px !important;
    border-radius: 99px !important;
    font-size: 11px !important;
    cursor: pointer;
}

.custom-avatar-group .MuiAvatar-root:nth-child(1) {
    z-index: 4;
}

.custom-avatar-group .MuiAvatar-root:nth-child(2) {
    z-index: 3;
}

.custom-avatar-group .MuiAvatar-root:nth-child(3) {
    z-index: 2;
}

.custom-avatar-group .MuiAvatar-root:nth-child(4) {
    z-index: 1;
}

.custom-avatar-group .MuiAvatar-root:hover {
    transform: scale(1.1); /* Add hover effect to enlarge avatar */
}

.like-btn {
    background-color: #1d3557 !important;
}

.like-btn path {
    color: #ffffff !important;
}

.love-btn {
    background-color: #f94144 !important;
}

.love-btn path {
    color: #ffffff !important;
}

.smile-btn {
    background-color: #f8961e !important;
}

.smile-btn path {
    color: #ffffff !important;
}

.angry-btn {
    background-color: #4d908e !important;
}

.angry-btn path {
    color: #ffffff !important;
}

.like-counts-box {
    border-radius: 3px !important;
    padding: 3px !important;
    background-color: #f9f9f9 !important;
}

.liked-post-text {
    color: blue !important;
}

.popup-list {
    max-height: 130px;
    overflow-y: auto;
}

.popup-avatar {
    position: relative;
    z-index: 1;
    width: 24px !important;
    height: 24px !important;
    border-radius: 99px !important;
    font-size: 11px !important;
    margin-right: 8px;
}

.popup-list-item {
    display: "flex";
    align-items: "center";
    padding: 1px;
    cursor: pointer;
}

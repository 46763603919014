.select-service {
    .select__control {
        min-height: 35px;

        .select__value-container {
            padding: 0 8px;
        }

        .select__input-container {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .select__indicators {
            .select__indicator {
                padding: 6px;
            }
        }
    }
}

.extra-contact-div {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
        background-color: red;
        color: white;
    }
}

.select__control--is-disabled {
    background-color: #F7F7F7 !important
}

.select__control--is-disabled .select__value-container .select__single-value {
    // color: #660C60 !important;
    font-size: 14px !important;
    color: #333333 !important;
    opacity: 50% !important;
}

.btn-danger {
    background-color: red !important;
    color: white !important;
    height: 100% !important;
}

.ack-5 {
    margin-right: 1px !important;
}

.content-card-body {
    padding: 30px !important;
}

.search-att-btn {
    font-size: 12px;
    padding: 10px 15px !important;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* border-radius: 6px !important; */
}

.user-tab-list .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root {
    min-width: auto !important;
}
.authentication-wrapper {
    background-image: url('../assets/images/bg.jpg');
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;

    .card {
        max-width: 362px;
        width: 100%;

        .card-header {
            border-bottom: 1px solid var(--border);
            padding: 22px;
            background-color: transparent;
        }

        .card-body {
            padding: 30px;

            @media(max-width:768.98px) {
                padding: 15px 15px 30px 15px;
            }

            .btn {
                max-width: 199px;
                margin-top: 30px;
            }
        }
    }
}

.input-field-username .MuiInputBase-root fieldset {
    border: 1px solid #EEEEEE !important;
}

.input-field-password .MuiInputBase-root fieldset {
    border: 1px solid #EEEEEE !important;
}

.external-login-div {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.external-login-div a {
    background-color: #EA4335;
    color: #FFFFFF;
    font-size: 16px;
}

.external-login-div a:hover {
    background-color: #EA4335;
    color: #FFFFFF;
    font-size: 16px;
}
.main-div-2-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 12px
}

.author-avtar {
    height: 32px !important;
    width: 32px !important;
}

.tbl-body-cell {
    background-color: #fff !important;
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    padding: 10px 15px 10px 15px !important;
    border-bottom: 1px solid #EEEEEE !important;
}

.tbl-body-cell-2 {
    background-color: #fff !important;
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    padding: 10px 0 10px 35px !important;
    border-bottom: 1px solid #EEEEEE !important;
}

.tbl-border-left {
    border-left: 1px solid #EEEEEE !important;
}

.tbl-border-right {
    border-right: 1px solid #EEEEEE !important;
}

.fs-14 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: 'Inter' !important;
    color: #333333 !important;
}

.chip-label-div {
    height: auto !important;
    background-color: #F9F9F9 !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
}

.chip-label-div span {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 10px !important;
    color: #333333 !important;
    opacity: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px !important;
}

.chip-label-div-2 {
    height: auto !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
}

.chip-label-div-2 span {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 9px !important;
    line-height: 10px !important;
    color: #333333 !important;
    opacity: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px !important;
}

.tbl-body-cell-3 {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
    background-color: #fff !important;
}

.search-changelog {
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    background: none;
    border: none;
}


.export-csv-btn {
    border: 1px solid #660C60 !important;
    color: #660C60 !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    // line-height: 14.52px !important;
    height: 37px;
    width: 100%;
}

.search-changelog-icon {
    color: #333333;
}

.changelog-table-div {
    background: none !important;
    color: none;
    border-radius: 4px;
    box-shadow: none !important;
    width: 100%;
    margin-bottom: 30px;
}

.tbl-changelog {
    background-color: #f9f9f9 !important;
    border: 1px solid #eeeeee !important;
    height: 42px !important;
    margin-top: 47px !important;
    // padding-left: 15px !important;
    // padding-right: 30px !important;
    // padding-bottom: 5px !important;

}

.tbl-cell-5 {
    padding: 10px 0 10px 15px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.extra-row {
    height: 9px;
}

.tbl-cell-2 {
    border-bottom: none !important;
}

.changelog-body {
    padding: 30px !important;
}

.tbl-cell-3 {
    border-bottom: none !important;
    padding-left: 0 !important;
}

.MuiTablePagination-root {
    .MuiToolbar-regular {
        padding-left: 0 !important;
        gap: 24px;
        justify-content: space-between;

        p {
            color: #959595;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;

            span {
                font-weight: 700;
            }
        }

        .MuiTablePagination-spacer {
            display: none;
        }

        .MuiTablePagination-input {
            display: none;
        }

        .MuiTablePagination-actions {
            padding-left: 0;
            margin-left: 0;
            gap: 8px;
            display: flex;


            .MuiIconButton-root {
                border: 1px solid var(--primary-color);

                svg {
                    path {
                        color: var(--primary-color);
                    }
                }

                &.Mui-disabled {
                    border: 0;

                    svg {
                        path {
                            color: var(--textgrey);
                        }
                    }
                }
            }
        }
    }
}

.search-changelog-input {
    input {
        padding-right: 70px;
    }
}

.search-changelog:hover {
    background: none !important;
}

.csv-btn-a {
    text-decoration: none !important;
}

.icon-div {
    height: 41px;
    width: 41px;
    border-radius: 3px;
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    cursor: pointer;
}

.customization-icon-color {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;

    @media(max-width:1024px) {
        flex-direction: column !important;
        gap: 10px
    }

    @media(max-width:768px) {
        flex-direction: row !important;
    }
}

.icon-color-picker {
    width: 118px !important;
}

.icon-color-picker .MuiInputAdornment-root button {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 1px #EEEEEE;
}

.icon-color-picker input {
    font-size: 11px !important;
}

.icon-color-picker2 {
    width: 118px !important;
}

.icon-color-picker2 .MuiInputAdornment-root button {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 1px #660C60;
}

.icon-color-picker2 input {
    font-size: 11px !important;
}

.selected-icon {
    border: 1px solid #660C60 !important;
}

.selected-icon .icon {
    color: #660C60 !important;
}

.icon {
    font-weight: 900 !important;
    font-size: 12px !important;
    line-height: 12px !important;
    color: #333333 !important;

}

.publish-div {
    @media(max-width:769px) {
        flex-direction: column-reverse !important;
    }
}

.card-publish {
    @media(max-width:769px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.card-publish button {
    @media(max-width:769px) {
        margin-top: 15px;
        margin-bottom: 10px;
    }
}

.icons-div {
    @media(max-width:1200px) {
        height: 45px;
        width: 45px;
    }
}

.tbl-cell-attribute-2 {
    border-bottom: none !important;
    padding: 11px 11px !important;
    /* height: 32px !important; */
}

.action-btn-icon-btn {
    padding: 0 !important;
}

.action-btn {
    color: #333333 !important;
}

.primary-btn {
    @media(max-width:425px) {
        width: 100%;
    }
}

.checkbox-type span {
    padding: 0 !important;
    margin-right: 8px !important;
    height: 16px !important;
}
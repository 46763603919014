.page-title {
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border);

  @media (max-width: 991.98px) {
    padding-bottom: 20px;
  }
}

.card-wrapper {
  .card-header {
    font-size: 12px;
    line-height: 15px;
    padding: 15px;
    border-bottom: 1px solid var(--border);
  }

  .attribute-card-body {
    padding: 0px;

    @media (max-width: 991.98px) {
      padding: 15px;
    }
  }
}

.new-checkbox {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      &~.checkmark {
        background-color: transparent;
        border-color: var(--priamry-color);

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    border-color: var(--primary-color);

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 0px;
      width: 5px;
      height: 8px;
      border: solid var(--primary-color);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.upload-img-wrapper {
  img {
    width: 100%;
    height: 144px;
    object-fit: cover;
    border-radius: 3px;
  }
}

.change-direction {
  @media (max-width: 767.98px) {
    flex-direction: column-reverse;
  }
}

.box-attribute {
  padding: 0 0px 0 0;
}

.box-attribute-2-view-post {
  // padding: 0 15px 15px 15px;
  border-bottom: 1px solid #eeeeee !important;
}

.MuiTabs-scroller .MuiTabs-flexContainer .non-selected-tab-attribute {
  // opacity: 50%;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #959595 !important;
  text-transform: inherit !important;
  min-height: 45px;
  // padding: 0 !important;
  justify-content: flex-start !important;
}

.MuiTabs-scroller .MuiTabs-flexContainer .selected-tab-attribute {
  color: #660c60 !important;
  text-transform: inherit !important;
  min-height: 45px;
  // padding: 0 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  justify-content: flex-start !important;
}

.add-att-tab .MuiTabs-scroller .MuiTabs-flexContainer .non-selected-tab-attribute {
  // opacity: 50%;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #959595 !important;
  text-transform: inherit !important;
  min-height: 45px;
  padding: 0 !important;
  justify-content: flex-start !important;
}

.add-att-tab .MuiTabs-scroller .MuiTabs-flexContainer .selected-tab-attribute {
  color: #660c60 !important;
  text-transform: inherit !important;
  min-height: 45px;
  padding: 0 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  justify-content: flex-start !important;
}

.drawer-menu-icon {
  font-weight: 400;
}

// .drawer .MuiPaper-elevation {
//     padding-top: 125px;
//     padding-bottom: 30px;
//     height: 100vh;
//     max-width: 360px !important;
//     width: auto !important;
// }

.false .MuiDrawer-paper {
  padding-top: 125px;
  padding-bottom: 0;
  height: 100vh;
  max-width: 360px !important;
  width: auto !important;
}

#side-bar-drawer-true {
  .MuiDrawer-paper {
    padding-top: 125px;
    padding-bottom: 0;
    height: 100vh;
    width: 320px !important;
  }
}

.short-drawer-width .MuiDrawer-paper {
  padding-top: 125px;
  padding-bottom: 30px;
  height: 100vh;
}

.drawer-open {
  width: calc(100% - 320px);
  margin-left: 320px;

  @media (max-width: 991px) {
    position: fixed;
    left: 0;
    width: calc(100% - 65px);
    margin-left: 65px;
  }
}

.drawer-close {
  margin-left: 107px;
}

.ck-dropdown {
  z-index: 0;
}

.select__menu {
  z-index: -1;
}

.extra-links-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extra-link-div-title {
  margin-top: 10px !important;
}

.upload-img-wrapper-2 img {
  width: 100%;
  height: 144px;
  object-fit: contain;
  border-radius: 3px;
}

.error-on-class {
  color: red !important;
}

.error-on-ir {
  color: red !important;
}

.error-on-eng {
  color: red !important;
}

.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.news-imag-div {
  img {
    width: 100%;
    height: 349px;
    object-fit: cover;
    border-radius: 6px 6px 0 0;
    border: 1px solid #f9f9f9;
  }
}

.view-news-short-desc {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  color: #333333;
}

.view-news-content {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #333333;
  margin-top: 15px !important;
}

.MuiBreadcrumbs-ol li {
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 38px !important;
}

.MuiBreadcrumbs-ol li svg {
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 38px !important;
}

.MuiBreadcrumbs-ol li h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}

.menu-name span {
  font-size: 15px;
  // color: #333333;
  font-weight: 400;
  line-height: 15px;
  // opacity: 50%;
  color: #959595;
}

.menu-icon {
  font-size: 15px;
  color: #959595 !important;
  font-weight: 400;
  line-height: 15px;
}

.menu-icon-2 {
  font-size: 15px;
  color: #660c60;
  font-weight: 400;
  line-height: 15px;
}

.selected-menu-item .menu-icon {
  // color: #ffff !important;
  color: #660c60 !important;
  font-weight: 900 !important;
}

.selected-menu-item .menu-icon-2 {
  color: #ffff !important;
}

.selected-menu-item .menu-name span {
  // color: #ffff !important;
  color: #660c60 !important;
  opacity: inherit;
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .selected-menu-item .MuiListItemText-root .MuiTypography-root .MuiBox-root .folder-name-text {
//     color: #ffff !important;
// }

.content-card-body {
  padding: 30px !important;
}

.search-changelog-icon {
  color: #333333;
}

.search-changelog-input {
  input {
    padding-right: 70px;
  }
}

.search-changelog:hover {
  background: none !important;
}

.ack-5 {
  margin-right: 1px !important;
}

.menu-name-media span {
  font-size: 14px;
  font-weight: 400;
  color: #959595;
  line-height: 16px;
}

.menu-name-media-selected span {
  font-size: 14px;
  font-weight: 400;
  color: #660c60;
  line-height: 16px;
}

.menu-icon-media {
  font-size: 12px;
  color: #959595 !important;
  font-weight: 900;
  line-height: 12px;
}

.menu-icon-media-selected {
  font-size: 12px;
  color: #660c60;
  font-weight: 900;
  line-height: 12px;
}

.media-folder-list-grid {
  border: 1px solid #eeeeee;
  height: 534px;
  overflow: auto;
}

.media-selected-type-border-left {
  border-right: 1px solid #660c60;
}

.media-list-grid {
  padding-left: 0 !important;
}

.main-upload-media-div {
  height: 44px;
  border: 1px solid #eeeeee;
  padding: 20px 15px 20px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-upload-media-div-2 {
  height: 44px;
  border: 1px solid #eeeeee;
  padding: 20px 15px 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btns-upload-media-div {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.btn-upload-media-div {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.media-upload-icon {
  font-size: 12px;
  font-weight: 400;
  color: #660c60;
  line-height: 12px;
}

.media-upload-text {
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  line-height: 15px;
}

.media-img-lists {
  background: #f9f9f9 !important;
  height: 414px;
  padding: 10px;
  overflow: auto;
}

.media-grid {
  width: 120px;
  height: 120px;
}

.card-media-2 {
  height: 100%;
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 3px;
}

.img-container-2 {
  height: 100% !important;
  width: 100%;
}

.media-img-2 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.media-pagination-main-div {
  padding: 0 15px 0 15px;
}

.media-pagination-main-div .media-pagination .MuiToolbar-root {
  gap: 0 !important;
}

.media-pagination-main-div .media-pagination .MuiToolbar-root .MuiTablePagination-spacer {
  display: contents !important;
}

.media-pagination-main-div .media-pagination .MuiToolbar-root .MuiInputBase-root {
  display: flex;
  margin-left: 0 !important;
  margin-right: 32px !important;
  width: 75px !important;
  border: 1px solid #eeeeee !important;
  border-radius: 3px !important;
}

.media-pagination-main-div .media-pagination .MuiToolbar-root .MuiInputBase-root .MuiTablePagination-select {
  text-align-last: left !important;
}

.media-pagination-main-div .media-pagination .MuiToolbar-root .MuiTablePagination-actions {
  padding-left: 140px !important;
}

.no-data-media {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
}

.media-pagination-main-div-2 {
  padding: 0 15px 0 15px;
}

.selected-media-file-2 {
  border: 2px solid #660c60;
  border-radius: 3px;
  padding: 5px;
}

.publish-bar-box {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 120px;
  z-index: 9;
  margin-top: 30px;
}

.delete-div-box {
  display: flex;
  gap: 10px;
  padding: 10px 15px 10px 15px;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
}

.delete-div-box-icon {
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 400 !important;
  color: #f94144 !important;
}

.delete-div-box-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  // line-height: 14.52px !important;
  color: #f94144 !important;
}

.last-div-box {
  display: flex;
  gap: 5px;
}

.preview-div-box {
  display: flex;
  gap: 10px;
  padding: 10px 15px 10px 15px;
  align-items: center;
}

.preview-div-box-icon {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  color: #959595 !important;
}

.preview-div-box-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.52px !important;
  color: #959595 !important;
}

.save-draft-div-box {
  display: flex;
  gap: 10px;
  // padding: 10px 15px 10px 15px;
  align-items: center;
}

.save-draft-div-box-icon {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  color: #959595 !important;
}

.save-draft-div-box-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.52px !important;
  color: #959595 !important;
}

.publish-btn-div-box {
  display: flex;
  gap: 10px;
  /* padding: 10px 15px 10px 15px; */
  align-items: center;
}

.form-row {
  justify-content: center !important;
}

.form-col {
  max-width: 754px !important;
  width: 100% !important;
}

.add-new-title {
  font-size: 18px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.ck.ck-editor {
  width: 100% !important;
}

.no-att-text {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.add-att-tab {
  border-bottom: 1px solid #eeeeee;
}

.add-att-tab .MuiTabs-flexContainer {
  gap: 30px;
}

.MuiTabs-indicator {
  background-color: #660c60 !important;
}

.att-list-div {
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.selected-att {
  border: 1px solid;
}

.att-icon {
  font-size: 18px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: #660c60 !important;
}

.att-assigned-label-box {
  width: 30%;
  // display: flex;
  // gap: 10px;
  // align-items: center;
  // justify-content: start;
}

.att-assigned-field-box {
  width: 70%;
}

.time-field {
  background-color: #f7f7f7 !important;
}

.time-field .MuiInputBase-root {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #333333 !important;
  opacity: 50% !important;
  line-height: 16.94px !important;
}

.att-acc-body {
  padding: 0 !important;
}

.att-list-box {
  border-bottom: 1px solid #eeeeee;
}

.att-action-box {
  padding: 15px;
  display: flex;
  justify-content: center;
}

.att-action-box-2 {
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: center;
}

.att-assigned-label-box-2 {
  width: 15%;
}

.att-assigned-field-box-2 {
  width: 85%;
}

@media(max-width: 768px) {
  .form-col {
    max-width: 100% !important;
  }
}
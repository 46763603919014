//CSS for engagement  modal

.highlight-engagement-model-title-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 15px 30px 15px 30px !important;
}

.engagement-modal-title {
    color: #333333 !important;
}

.engagement-close-btn {
    height: 36px;
    width: 36px;
    border: 1px solid #eeeeee !important;
}

.enagagement-modal-dialog-content {
    padding: 0 !important;
}

.engagement-modal-tab-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: none !important;
}

.engagement-modal-tabs .Mui-selected {
    color: #660c60 !important;
}

.engagement-modal-tab-label .label-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #eeeeee;
    height: 18px;
    width: 18px;
}

.engagement-modal-tabs .MuiTabs-scrollButtons {
    width: auto;
    visibility: hidden;
}

.engagement-modal-tabs .MuiTabs-scrollButtons.Mui-disabled {
    visibility: hidden;
    display: none;
}

.engagement-modal-tabs .MuiTabs-scrollButtons:not(.Mui-disabled) {
    visibility: visible;
    padding: 4px;
}

.label-emoji {
    width: 12px !important;
    height: 12px !important;
    border-radius: 99px !important;
    padding: 5px !important;
}

.label-emoji path {
    font-size: 7px !important;
}

.engagement-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.author-avtar-creator-engagement {
    height: 32px !important;
    width: 32px !important;
}

.divider {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.engagement-divider {
    margin: 8px 15px 8px !important;
}

.start-conversation-btn {
    color: #660c60 !important;
    border: 1px solid #660c60 !important;
    border-radius: 3px !important;
    padding: 10px !important;
}

.view-profile-btn {
    color: #660c60 !important;
    border: 1px solid #660c60 !important;
    border-radius: 3px !important;
}

.users-con-chat-list-div {
    border-right: 1px solid #eeeeee;
    // padding: 15px;
    width: 30%;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }
}

.user-con-chat-div {
    width: 70%;
    padding: 0 10px;
    position: relative;
}

.serach-box-user-con {
    max-width: 40.54%;
    flex: 0 0 40.54%;
    min-width: 240px;

    @media (max-width: 991px) {
        display: none;
    }
}

.search-input-3 {
    border: 1px solid #eeeeee;
    border-radius: 100px !important;
    background-color: #fff !important;
    padding: 14px 84px 14px 15px;
    resize: none;
    line-height: 16px;

    max-height: 60px !important;
    overflow: auto !important;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }

    &::placeholder {
        padding-top: 4px;
    }
}

.search-input-3:focus {
    border-color: #eeeeee !important;
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 0 !important;
}

.search-btn {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    min-width: 38px !important;
    padding: 0 !important;
    width: 38px !important;
    height: 38px;
    border-radius: 100px !important;
    position: absolute !important;
    right: 4px;
    margin: 4px 5px 4px 4px;
    top: 4px;
}

.search-btn svg {
    color: #660c60;
}

input.form-control::placeholder {
    color: #777777;
    font-size: 14px;
    font-weight: 400;
}

.user-chats-div-con {
    height: calc(100vh - 341px); // border-bottom: 1px solid #EEEEEE;
    overflow: auto;
    position: relative;
}

.create-conversation-dialog {
    padding: 0 !important;
}

.selected-chat-room {
    background-color: #EEEEEE;
}

.user-list {
    max-height: calc(100vh - 212px);
    overflow: auto;
}

.user-list-items-con {
    align-items: center !important;
    justify-content: center !important;
    padding: 12px 10px !important;
}

.left .message-content-con {
    // max-width: 100%;
    // word-wrap: break-word;
    // display: flex;
    // justify-content: flex-end;
    // background-color: #ffff;
    // color: #1f2542;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: 500px;
    // width: max-content;
}

.left .message-content-con .chat-msg-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 4px;
}

.left .message-content-con .chat-msg-box-1 .chat-message-ty {
    background: white;
    word-wrap: break-word;
    display: flex;
    justify-content: flex-end;
    /* background-color: white; */
    color: #1f2542;
    padding: 10px;
    border-radius: 12px;
    max-width: 500px;
    width: max-content;
}

.left .message-content-con-media {
    word-wrap: break-word;
    display: flex;
    justify-content: flex-end;
    color: #1f2542;
    border-radius: 12px;
    max-width: 500px;
    width: max-content;
}

.right .message-content-con p .url-link {
    color: white;
}

.right .message-content-con-media p .url-link {
    color: white;
}

.right .message-content-con {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            right: 0px;
        }
    }
}

.right .message-content-con-media {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            right: 0px;
        }
    }
}

.chat-media-box {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            right: 0px;
        }
    }
}

.right .pl__chat-wrap-reply {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            right: 0px;
        }
    }
}

.left .message-content-con {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            left: 0px;
        }
    }
}

.left .message-content-con-media {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            left: 0px;
        }
    }
}

.left .pl__chat-wrap-reply {
    position: relative;

    .reaction-popup-card {
        display: none;
    }

    &:hover {
        .reaction-popup-card {
            position: absolute;
            display: flex;
            top: -42px;
            left: 0px;
        }
    }
}

.icon-btn-reaction {
    position: relative;

    .edit-chat-popup {
        display: none;
    }

    .temp-chat-popup {
        display: none;
    }

    &:hover {
        .temp-chat-popup {
            position: absolute;
            display: flex;
            // top: -2px;
            // left: 10px;
            // right: 0px;
            height: 150px;
            background-color: transparent;
            min-width: 100%;
        }

        .edit-chat-popup {
            position: absolute;
            display: flex;
            top: -114px;
            left: -84px;
            right: 80px;
            background-color: #ffff;
            min-width: 124px;
        }

        .edit-chat-popup.popup-top {
            top: -220px;
            left: -96px;
        }

        .edit-chat-popup.popup-bottom {
            top: 40px;
            /* Adjust based on the size of your button */
            left: -96px;
        }
    }
}

.icon-btn-reaction-2 {
    position: relative;

    .edit-chat-popup {
        display: none;
        // position: absolute;
        // display: flex;
        // top: -90px;
        // left: -80px;
        // right: 78px;
        // background-color: #ffff;
        // min-width: 124px;
    }

    .temp-chat-popup {
        display: none;
        // position: relative;
        // display: flex;
        // top: -173px;
        // left: -96px;
        // right: 80px;
        // background-color: transparent;
        // min-width: 100%;
        // justify-content: center;
        // align-items: center;
    }

    &:hover {
        .temp-chat-popup {
            position: absolute;
            display: flex;
            height: 150px;
            background-color: transparent;
            min-width: 100%;
        }

        .edit-chat-popup {
            position: absolute;
            display: flex;
            top: -15px;
            left: -80px;
            right: 78px;
            background-color: #ffff;
            min-width: 124px;
        }

        .edit-chat-popup.popup-top {
            top: -220px;
            left: -96px;
        }

        .edit-chat-popup.popup-bottom {
            top: 40px;
            /* Adjust based on the size of your button */
            left: -96px;
        }
    }
}

.icon-btn-reaction-3 {
    position: relative;

    .edit-chat-popup {
        display: none;
    }

    &:hover {
        .edit-chat-popup {
            position: absolute;
            display: flex;
            top: -172px;
            left: -82px;
            right: 80px;
            background-color: #ffff;
            min-width: 124px;
        }

        .edit-chat-popup.popup-top {
            top: -220px;
            left: -96px;
        }

        .edit-chat-popup.popup-bottom {
            top: 40px;
            /* Adjust based on the size of your button */
            left: -96px;
        }
    }
}

.icon-btn-reaction-4 {
    position: relative;

    .edit-chat-popup {
        display: none;
    }

    &:hover {
        .edit-chat-popup {
            position: absolute;
            display: flex;
            top: -170px;
            left: -82px;
            right: 80px;
            background-color: #ffff;
            min-width: 124px;
        }

        .edit-chat-popup.popup-top {
            top: -220px;
            left: -96px;
        }

        .edit-chat-popup.popup-bottom {
            top: 40px;
            /* Adjust based on the size of your button */
            left: -96px;
        }
    }
}

.right .message-content-con {
    // word-wrap: break-word;
    // display: flex;
    // background-color: #660c60;
    // color: #ffffff;
    // padding: 10px;
    // border-radius: 12px;
    // max-width: 500px;
    // width: max-content;
}

.right .message-content-con .chat-msg-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    gap: 4px;
    // background: #660c60;
    // color: #ffffff;
    // border-radius: 0 0 12px 12px;
}

.right .message-content-con .chat-message-ty {
    background: #660c60;
    color: #ffffff;
    padding: 10px;
    border-radius: 12px;
    max-width: 500px;
    width: max-content;
    word-wrap: break-word;
}

.right .message-content-con-media {
    word-wrap: break-word;
    display: flex;
    color: #ffffff;
    border-radius: 12px;
    max-width: 500px;
    width: max-content;
    background-color: #660c60;
}

.chat-unread-count {
    color: #eeeeee;
    background-color: #1f2542;
    border-radius: 50%;
    min-width: 18px;
    max-width: max-content;
    text-align: center;
    font-size: 9px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Chat message styles */
.chat-message-con {
    display: flex;
    // align-items: center;
    padding: 0 0 0 0;
    border-radius: 10px;
    flex-direction: column;
    // margin: 2px 0;
}

.chat-message-con.left {
    justify-content: flex-start;
    // background-color: #F9F9F9;
    // color: #1F2542;
    // padding: 10px;
    // border-radius: 12px;
    // width: max-content;
    display: flex;
    // flex-direction: column;
    max-width: 100%;
}

.chat-message-con.right {
    justify-content: flex-end;
    // background-color: #660C60;
    // color: #FFFFFF;
    // padding: 10px;
    // border-radius: 12px;
    // width: max-content;
    display: flex;
    // flex-direction: column;
    max-width: 100%;
}

.left .chat-data-con {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3px;
}

.right .chat-data-con {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
}

.message-time {
    margin: 0 25px;
}

.message-content-con p {
    font-size: 14px !important;
    font-weight: 400 !important;
    white-space: pre-wrap;
    word-break: break-word;
}

.message-content-con-media p {
    font-size: 14px !important;
    font-weight: 400 !important;
    white-space: pre-wrap;
    word-break: break-word;
}

.message-date {
    color: #1f2542;
    font-weight: 700 !important;
    font-size: 12px !important;
}

.message-date::before {
    border-top: 1px solid #333333 !important;
}

.message-date::after {
    border-top: 1px solid #333333 !important;
}

.chat-usr-name {
    color: #1f2542;
    font-weight: 400 !important;
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
}

.chat-msg-time {
    font-size: 12px !important;
    color: #777777 !important;
}

.load-more-btn-div {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.message-date::before {
    border-top: 1px solid #333333 !important;
}

.message-date::after {
    border-top: 1px solid #333333 !important;
}

.typing-indicator {
    position: sticky;
    left: 0;
    z-index: 99;
    right: 0;
    margin: 0 auto;
    top: 89%;
    display: inline-block;
    width: 100%;
}

.typing-indicator .typing-indicator-text {
    background-color: #333333 !important;
    color: #fff !important;
    border-radius: 6px;
    height: 34px !important;
    padding: 5px 12px !important;
    margin: 0 auto;
}

.chat-msg-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.load-more-btn {
    display: flex;
    gap: 10px;
    background-color: #eeeeee !important;
    color: #333333 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border: 1px solid #eeeeee;
}

.last-msg {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.conversation-dialog .MuiDialog-container .MuiPaper-root {
    height: 540px;
}

.edit-con-btns {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.right .chat-data-con .MuiBox-root .MuiBox-root .message-time {
    display: flex;
    justify-content: flex-end;
}

.left .chat-data-con .MuiBox-root .MuiBox-root .message-time {
    display: flex;
    justify-content: flex-start;
}

.main-div-user-list {
    display: flex;
    justify-content: space-between;
}

.main-box-2 {
    padding: 5px 15px 5px 15px;
}

.selected-users {
    font-size: 12px;
    font-weight: 400;
    color: #660c60;
}

.create-con-popover .MuiPaper-root {
    min-width: 312px !important;
    top: 170px !important;
}

.edit-chat-popover .MuiPaper-root {
    min-width: 124px !important;
}

.no-chat-img {
    height: 300px;
}

.chat-room-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
    font-size: 18px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    color: #333333 !important;
}

.last-chat-msg {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px !important;
    font-size: 11px !important;
    color: #959595 !important;
}

.edit-popover-icon {
    font-size: 12px;
    color: #660c60 !important;
    font-weight: 400;
}

.edit-popover-text {
    font-size: 14px;
    color: #959595 !important;
    font-weight: 400;
}

.leave-channel-icon {
    font-size: 12px;
    color: #e1593b !important;
    font-weight: 400;
}

.leave-channel-text {
    font-size: 14px;
    color: #e1593b !important;
    font-weight: 400;
}

.right .msgs {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.highlight {
    background-color: #e1593b !important;
    color: #fff !important;
    /* Highlight color */
}

.navigated {
    background-color: yellow !important;
    color: black !important;
}

.fa-clock-icon-send {
    margin: 15px 0 0 0;
}

.icon-btn-reaction:hover {
    background-color: white !important;
}

.icon-btn-reaction svg {
    border: 1px solid #eeeeee;
    border-radius: 99px;
}

.edit-btns {
    padding: 10px 15px 10px 15px !important;
}

.fa-love-btn-con {
    width: 11px !important;
    height: 11px !important;
    border-radius: 99px !important;
    background-color: #f94144 !important;
    padding: 5px !important;
}

.fa-love-btn-con path {
    font-size: 2px !important;
    color: #ffffff !important;
    font-weight: 400 !important;
    text-align: center !important;
}

.fa-smile-btn-con {
    width: 11px !important;
    height: 11px !important;
    border-radius: 99px !important;
    background-color: #f8961e !important;
    padding: 5px !important;
}

.fa-smile-btn-con path {
    font-size: 2px !important;
    color: #ffffff !important;
    font-weight: 400 !important;
    text-align: center !important;
}

.fa-angry-btn-con {
    width: 11px !important;
    height: 11px !important;
    border-radius: 99px !important;
    background-color: #4d908e !important;
    padding: 5px !important;
}

.fa-angry-btn-con path {
    font-size: 2px !important;
    color: #ffffff !important;
    font-weight: 400 !important;
    text-align: center !important;
}

.fa-like-btn-con {
    width: 11px !important;
    height: 11px !important;
    border-radius: 99px !important;
    background-color: #1d3557 !important;
    padding: 4px !important;
}

.fa-like-btn-con path {
    font-size: 2px !important;
    color: #ffffff !important;
    font-weight: 400 !important;
    text-align: center !important;
}

.reaction {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 7px;
    background-color: #eeeeee;
    color: #4d908e;
    border-radius: 12px;
}

.left .reaction-main-div {
    margin: 5px 30px 8px 30px;
}

.right .reaction-main-div {
    margin: 5px 0 8px 0;
}

.reaction-popover .MuiPaper-root {
    top: 370px !important;
}

.reaction span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.message-sender {
    margin-right: 4px;
}

.dot-separator {
    margin: 0 4px;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #959595 !important;
}

.message-sender-name {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #959595 !important;
}

.avatar-chat {
    height: 24px !important;
    width: 24px !important;
    font-size: 15px !important;
}

.pl__msg-replay+.user-chat-send-div {
    padding: 8px 0 !important;
}

.pl__msg-replay+.user-chat-send-div textarea {
    border: 0;
    padding: 12px;
    border-radius: 0 !important;
}

.pl__chat-wrap {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    padding: 8px 0px 4px 8px;
    border-radius: 24px;
}

.pl__person-name {
    font-size: 14px;
    padding: 0 0 8px;
}

textarea:focus {
    outline: 0;
}

.pl__msg-deatils {
    color: #999999;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.pl__msg-replay {
    display: flex;
    position: relative;
    padding: 10px 0 0 14px;
}

.pl__msg-replay .pl__close-btn {
    width: 30px;
}

.pl__msg-replay .pl__msg-block {
    width: calc(100% - 30px);
    margin-right: 16px;
}

.user-chat-send-div {
    padding: 8px 0 !important;
}

.pl__person-name span {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #660c60;
    text-align: center;
    line-height: 19px;
    color: #ffffff;
    font-weight: 600;
    margin: 0 8px 0 0;
    font-size: 12px;
}

.user-chat-send-div .input-group {
    align-items: end;
}

.serach-box button {
    // margin: 0 !important;
    // bottom: 6px;
    // right: 7px;
}

.border-send-message-div {
    border-radius: 15px 20px 20px 15px !important;
}

.highlight-quoted-message {
    background-color: #eeeeee;
    /* Change this to your preferred color */
    transition: background-color 0.3s ease-in-out;
}

/* reply qoute style start */
.pl__msg-replay:before {
    content: "";
    top: 0;
    left: 0px;
    position: absolute;
    background-color: transparent;
    border-left: 3px solid #660c60;
    border-top: 3px solid #660c60;
    border-top-left-radius: 16px;
    margin-left: 0;
    min-width: 14px;
    width: 4px;
    height: 100%;
}

.pl__chat-wrap-reply .pl__msg-replay {
    padding: 16px 0 12px 12px;
}

.pl__chat-wrap-reply .pl__person-name span {
    width: 22px;
    height: 22px;
    background-color: #660c60;
    text-align: center;
    line-height: 19px;
    color: #ffffff;
    font-weight: 600;
    margin: 0 8px 0 0;
    font-size: 12px;
}

.pl__chat-wrap-reply .pl__msg-replay .pl__msg-block {
    width: 100%;
    margin-right: 16px;
}

.right .pl__chat-wrap-reply .message-content-con {
    max-width: 100%;
    width: 100%;
    border-radius: 0 0 12px 12px;
}

.right .pl__chat-wrap-reply .message-content-con p {
    background-color: #660c60;
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 12px 12px;
    width: 100%;
}

.right .pl__chat-wrap-reply .message-content-con .chat-msg-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    gap: 4px;
    background-color: #660c60;
    border-radius: 0 0 12px 12px;
    color: white;
}

.right .pl__chat-wrap-reply .message-content-con-media {
    max-width: 100%;
    width: 100%;
    border-radius: 0 0 12px 12px;
}

.pl__chat-wrap-reply {
    background-color: #ffffff;
    max-width: 500px;
}

.left .pl__chat-wrap-reply .message-content-con {
    background-color: #ebebeb;
    border-radius: 0 0 12px 12px;
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
}

.left .pl__chat-wrap-reply .message-content-con p {
    background-color: white;
    color: #1f2542;
    // padding: 10px;
    border-radius: 0 0 12px 12px;
    width: 100%;
}

.left .pl__chat-wrap-reply .message-content-con .chat-msg-box-1 {
    background-color: white;
    color: #1f2542;
    padding: 10px;
    border-radius: 0 0 12px 12px;
    width: 100%;
}

.left .pl__chat-wrap-reply .message-content-con-media {
    border-radius: 0 0 12px 12px;
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
}

.media-img-3 {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.serach-box .search-btn-dash-6 {
    position: absolute !important;
    margin: 12px 24px !important;
    bottom: 1px;
    right: 0px;
}

.chat-media-box {
    height: 160px;
    width: 250px;
    border-radius: 20px;
    cursor: pointer;
    // padding: 15px;
}

.chat-media-box img {
    width: 100%;
    height: 100%;
}

.other-type-media-box {
    height: 80px;
    width: 80px;
}

.txt-area-resize {
    resize: vertical;
    min-height: 46px;
    max-height: 100px !important;
}

.mention-text {
    font-weight: 600 !important;
}

.user-list {
    padding-top: 15px !important;
    padding-right: 15px !important;
}

.pl__chat-wrap-reply .msgs div .message-content-con-media .chat-media-box {
    height: 160px;
    width: 250px;
    border-radius: 20px;
    cursor: pointer;
    padding: 15px;
}

.pl__chat-wrap-reply .msgs div .message-content-con .chat-msg-box-1 .chat-media-box {
    height: 160px;
    width: 250px;
    border-radius: 20px;
    cursor: pointer;
    padding: 15px;
}

.quote-other-media-box {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
}

.right .reply-media-data-box {
    padding: 10px 0 10px 0;
    // background-color: #660c60;
    color: #ffff;
    border-radius: 12px;
}

.reply-media-data-box-quote {
    padding: 10px;
    // background-color: #660c60;
    color: #ffff;
    border-radius: 12px;
}

.left .reply-media-data-box {
    padding: 10px;
    background-color: #ffff;
    // color: #ffff;
    border-radius: 12px;
}

.single-img-media-box {
    // background-color: #660c60;
    // padding: 10px;
    // border-radius: 12px;
}

.chat-room-search-box {
    background-color: #FFFFFF !important;
    border: 1px solid #EEEEEE !important;
    border-radius: 6px !important;
}

.group-info-icon {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
    color: #333333 !important;
}

.search-btn-dash-5-con {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 99px !important;
    border: 1px solid #EEEEEE !important;
    padding: 0 !important;
}

.search-btn-dash-5-con svg {
    color: #660C60;
    font-size: 14px !important;
}

.send-message-img-box {
    border-top: 1px solid #EEEEEE;
    padding: 5px;
}

.search-input-2__control textarea {
    padding: 10px !important;
}

.img-box-chat {
    height: 650px;
}

.show-medi-box {
    width: min-content;
    position: relative;
}
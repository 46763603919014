.users-notification-list-div {
    width: 100%;
    max-height: 55vh;
}

.notification-popover {
    height: auto;
    width: 515px;
    max-width: 90vw;
    // overflow: auto;
}

.notification-tab-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: none !important;
    color: inherit;
}

.notification-tabs .Mui-selected {
    color: #660c60 !important;
}

.notification-tab-label .label-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fb7900;
    height: 18px;
    width: 18px;
}

.notification-list-item:hover {
    cursor: pointer;
}

.search-input-2 {
    max-height: 140px !important;
    overflow: auto;
}

.quick-chat-input {
    border: 1px solid #eeeeee;
    // border-radius: 100px !important;
    background-color: #fff !important;
    padding: 8px 43px 8px 9px;
    // padding: 14px 84px 14px 15px;
    resize: none;
    line-height: 16px;

    min-height: 40px;

    max-height: 60px !important;
    overflow: auto !important;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }

    &::placeholder {
        padding-top: 4px;
    }
}

.quick-chat-input:focus {
    border-color: #eeeeee !important;
}

.chat-media-box-2 {
    height: 70px;
    width: 140px;
    border-radius: 20px;
    cursor: pointer;
}

.chat-media-box-2 img {
    height: 100%;
    width: 100%;
}

.chat-media-box-2 .reply-media-data-card {
    padding: 0 !important;
}

// .reply-media-data-box-2 {
//     /* padding: 10px; */
//     background-color: #660c60 !important;
//     color: white;
//     border-radius: 12px;
//     padding: 10px !important;
// }

.reply-media-data-box-2 .reply-media-data-card {
    padding: 0 !important;
}

.quote-other-media-box {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
}

.header-chat-wrap-reply {
    max-width: 300px !important;
    background-color: #ffffff;
}

.pl__chat-wrap-reply .header-msg-reply {
    display: flex;
    position: relative;
    padding: 8px 0 4px 4px !important;
}

.pl__person-name {
    font-size: 14px;
    padding: 0 0 8px;
}

.pl__msg-deatils {
    color: #999999;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.right .pl__chat-wrap-reply .header-quote-msg-div-1 .message-content {
    max-width: 100%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    position: relative;
}

.right .pl__chat-wrap-reply .header-quote-msg-div-1 .message-content .chat-msg-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    background-color: #660c60;
    border-radius: 0 0 12px 12px;
    color: white;
}

.right .pl__chat-wrap-reply .header-quote-msg-div-1 .message-content p {
    background-color: #660c60;
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 12px 12px;
    width: 100%;
}

.pl__chat-wrap-reply .header-quote-msg-div-1 .message-content .chat-msg-box-1 .chat-media-box {
    height: 160px;
    width: 250px;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
}

.header-msg-blk {
    width: 160px !important;
    margin-right: 10px !important;
}

.reply-media-data-box-2-header {
    // padding: 10px;
    // background-color: white !important;
    border-radius: 12px;
    background-color: #fff !important;
}

.reply-media-data-box-2-header .reply-media-data-card {
    padding: 0 !important;
}

.other-type-media-box-header {
    height: 65px !important;
    width: 65px !important;
}

.other-type-media-box-header svg {
    font-size: 16px !important;
}

.header-quote-2-box {
    width: 110px !important;
}

.right .header-chat-wrap-reply {
    position: relative;
}

.right .header-chat-wrap-reply .msgs .header-quote-msg-div-1 {
    width: 100%;
    // background-color: #660c60;
    // color: #ffffff;
    // padding: 0;
    // border-radius: 0 0 12px 12px;
    // max-width: 230px;
    /* width: max-content; */
}

.left .chat-data-con .header-chat-wrap-reply .msgs .header-quote-msg-div-1 {
    width: 100% !important;
    background-color: #F9F9F9 !important;
    // color: #ffffff;
    padding: 10px !important;
    border-radius: 0 0 12px 12px !important;
    max-width: 230px !important;
    /* width: max-content; */
}

.header-chat-wrap-reply .msgs .header-quote-msg-div-1 .message-content .chat-msg-box-1 .chat-media-box {
    height: 70px;
    width: 160px;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
}

.reply-media-data-box-2 {
    padding: 10px;
    background-color: white !important;
    // color: white;
    border-radius: 12px;
    background-color: #660c60 !important;
}

.right .message-content .chat-message-box-1 {
    background: #660c60 !important;
    color: #ffffff !important;
    padding: 10px;
    border-radius: 12px;
    max-width: 500px;
    width: max-content;
    word-wrap: break-word;
}

.popup-chat--box {
    border: 1px solid #EEEEEE;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.chat-name-ty {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #333333 !important;
}

.see-all-chat-box {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.see-all-chat-text {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #959595 !important;
}

.see-all-chat-icon {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #959595 !important;
}

.header-chat-room-name {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    color: #333333 !important;
}

.header-chat-room-arrow-icon {
    font-size: 12px !important;
    line-height: 12px !important;
    font-weight: 400 !important;
    color: #660C60 !important;
}

.search-btn-dash {
    background-color: transparent !important;
    width: 30px !important;
    height: 30px;
    position: absolute !important;
    margin: 0px 0px 0px 0px !important;
    border: none !important;
    top: 4px;
    bottom: 0;
    right: 0;
}

.quick-chat-input__control textarea {
    padding: 10px !important;
}
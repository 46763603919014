.user-img-2 {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-desc-main-div {
    padding: 30px;
}

.people-card-wrapper-2 {
    padding: 30px;
}

.tbl-cell-group-1 {
    background: white;
}

.border-top-group {
    border-top: 1px solid #EEEEEE !important;
    border-bottom: none !important;
}

.border-bottom-group {
    border-bottom: 1px solid #EEEEEE !important;
}

.tbl-people-group {
    padding: 10px 30px 10px 30px;
}

.people-tabs {
    padding: 0 30px 0 30px;
}

.no-data-img-2 {
    height: 120px;
}

.user-grid-1 {
    height: 100% !important;
    box-shadow: none !important;
    min-width: 220px;
    max-width: 420px;
    padding-top: 0 !important;
}

.user-grid-2 {
    padding-top: 0 !important;
}

.user-info-box {
    padding: 30px;
    border-bottom: 1px solid #EEEEEE;
}

.user-info-name {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #333333 !important;
    margin-top: 15px !important;
    margin-bottom: 5px !important;
}

.user-info-job-title {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 14.52px !important;
    color: #959595 !important;
}

.user-contact-info-box {
    padding: 30px;
    border-bottom: 1px solid #EEEEEE;
}

.user-contact-info-contact-heading {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 14.52px !important;
    color: #660C60 !important;
}

.user-contact-info-contact-number {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 14.52px !important;
    color: #959595 !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}

.user-contact-info-contact-email {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 14.52px !important;
    color: #959595 !important;
}

.user-social-media-info-box {
    padding: 30px;
}

.user-social-media-info-icons-box {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.user-social-media-info-icon-box {
    border: 1px solid #EEEEEE;
    background: #F7F7F7;
    height: 40px;
    width: 40px;
    border-radius: 99px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-social-media-info-icon {
    font-size: 14px !important;
    color: #660C60 !important;
    font-weight: 900 !important;
    line-height: 14px !important;
}

.user-other-info-box {
    padding: 30px
}

.box-attribute-user-tab-data {
    padding: 0 30px !important;
}

.user-img-2 {
    display: flex;
    justify-content: start;
    align-items: start;
}

@media (max-width: 1536px) {
    .user-grid-2 {
        padding-top: 24px !important;
    }

    .user-info-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .user-img-2 {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .user-contact-info-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .user-social-media-info-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.add-folder-model-content {
    padding: 0 !important;
}

.tab-box-2 {
    padding: 20px;
}

.tab-box-3 {
    padding: 10px;
}

.box-attribute-2 {
    margin: 0 !important;
    border-bottom: none !important;
    padding: 0 15px 15px 15px;
}

.type-list-paper-2 {
    padding: 0;
    // max-height: 450px !important;
    // overflow-y: auto;
}

.media-img-div {
    width: 60%;
}

.media-info-div {
    width: 40%;
}

.media-dialog .MuiDialog-container .MuiPaper-root {
    max-width: 1000px !important;
    max-height: 800px !important;
    overflow: auto;
}

.media-dialog-2 .MuiDialog-container .MuiPaper-root {
    max-width: 600px !important;
    max-height: 800px !important;
    overflow: auto;
}

.media-info-div-2 {
    width: 100%;
    padding: 12px;
}

.image-zone {
    border: none;
    height: 100%;
}

.dropzone {
    border: 2px dashed #ddd;
    height: 100%;
}

.media-preview {
    padding: 0 !important;
}

.image-preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.audio-file {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.pdf-doc {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-media-access {
    margin-right: 0;
    margin-left: 0;
}

.media-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.folder-icon {
    height: 32px;
    width: 32px;
}

.system-icon {
    height: 15px;
    width: 15px;
}

.card-media {
    width: auto;
    height: 166px;
    border-radius: 3px;
    border: 1px solid #EEEEEE;
    cursor: pointer;
}

.media-item-name {
    padding: 5px;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.5px !important;
    color: #333333;
}

.selected-media-file {
    border: 2px solid #660C60;
    border-radius: 3px;
    padding: 10px;
}

.media-icons-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    align-items: flex-end;
}

.media-icons-div-2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    align-items: flex-end;
    width: max-content;
    position: absolute;
    top: 15px;
    right: 12px;
}

.img-container {
    position: relative;
    height: 100% !important;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.img-container:hover .media-img {
    opacity: 0.3;
}

.img-container:hover .middle {
    opacity: 1;
}

.folder-grid {
    position: relative;
}

.folder-grid .media-icons {
    width: max-content;
}

.drag-drop-text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .main-app-icon-div-4 {
        gap: 10px;
    }

    .media-img-div {
        width: 100%;
    }

    .media-info-div {
        width: 100%;
    }

    .dropzone {
        height: 150px;
    }

    .image-zone {
        height: 150px;
    }
}
.post-data {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.94px;
    color: #000000 !important;
    opacity: 50%;
}

.related-post-div {
    background-color: #660c60 !important;
    border-radius: 6px !important;
    height: 50px;
    color: #fbf8f1 !important;
}

.related-text {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 16px 20px 16px 20px;
}

.relate-item-img-div {
    height: 68px;
    width: 68px;
    border-radius: 3px;
    border: 1px solid #f9f9f9;
}

.relate-item-img-div img {
    height: 100%;
    width: 100%;
}

.related-post-title {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #333333 !important;
}

.related-read-more {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #660c60 !important;
}

.related-read-more svg {
    margin: 0 0 0 15px;
}

.fs-12-btn {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    color: #ffffff !important;
}

.fs-12-btn-2 {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    color: #333333 !important;
    border-color: #f9f9f9 !important;
}

.grid-details {
    padding: 15px;
}

.fs-14-muted {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #959595 !important;
}

.divider-details {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.author-avtar-details {
    height: 16px !important;
    width: 16px !important;
    font-size: 11px !important;
}

.rating-div {
    padding: 15px 15px 0 15px;
}

.rating-div span {
    gap: 20px;
}

.MuiRating-iconFilled {
    color: #660c60 !important;
}

.MuiRating-iconEmpty {
    color: #660c60 !important;
}

.fab-container {
    position: fixed;
    bottom: 21px;
    right: 21px;
    z-index: 7;
}

.custom-fab {
    background-color: #660c60 !important;
    color: #ffffff;
}

.custom-fab:hover {
    background-color: #55104e;
}

.badge-count {
    background-color: #f9f9f9;
    color: #333333;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    display: inline-block;
    vertical-align: middle;
}

.badge-count:hover {
    cursor: pointer;
}

.post-summary-div {
    background-color: #FFFFFF;
    padding: 30px;
}

.card-action-for-like-comment-count {
    border: 1px solid #EEEEEE;
    padding: 10px !important;
}

.card-action-for-like-comment-box {
    border: 1px solid #EEEEEE;
    padding: 10px !important;
}

.card-action-for-like-comment-box-1 {
    border: 1px solid #EEEEEE;
    padding: 15px !important;
    flex-direction: column !important;
    gap: 10px;
}

.post-detail-comment-box {
    border: 1px solid #eeeeee !important;
    background-color: #f9f9f9 !important;
    padding: 15px;
    border-radius: 6px !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    width: max-content;
    padding: 15px;
}

.post-detail-comment-reply-toggle {
    padding-left: 62px;
}

.post-detail-comment-reply-box-2 {
    padding-left: 62px;
}

.post-detail-comment-box-2 {
    border: 1px solid #eeeeee !important;
    background-color: #f9f9f9 !important;
    padding: 15px;
    border-radius: 6px !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    width: max-content;
    padding: 10px;
}

.main-grid-2 {
    padding-top: 0 !important;
}

.news-imag-div .carousel .carousel-inner {
    height: 100% !important;
}

@media (max-width: 1199px) {
    .main-grid-post {
        width: 100% !important;
    }

    .main-grid-2 {
        padding-top: 20px !important;
        padding-left: 0 !important;
    }
}
.main-content-data-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-img {
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
    width: 100px;
}

.people-card-wrapper {
    padding: 30px;
    height: 100% !important;
}

.icon-div-user-group-2 {
    height: 16px;
    width: 16px;
    border-radius: 99px;
    /* border: 1px solid #EEEEEE; */
    cursor: pointer;
}

.icon-div-user-group-2 svg {
    height: 7px;
    width: 7px;
}

.chip-label-div-user {
    height: auto !important;
    background-color: #f9f9f9 !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
}

.chip-label-div-user span {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 9px !important;
    line-height: 10px !important;
    color: #333333 !important;
    opacity: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px !important;
}

.select__value-container {
    padding: 2px 5px !important;
}

.folder-name-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #333333;
}

/* Blink effect */
.blink-effect {
    animation: blink 1s ease-in-out 3;
}

@keyframes blink {
    0% {
        background-color: #660c601a;
    }

    50% {
        background-color: transparent;
    }

    100% {
        background-color: #660c601a;
    }
}

.breadcrumb-text-1 {
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 38px !important;
    color: #333333 !important;
    opacity: 30% !important;
}

.breadcrumb-text-2 {
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 38px !important;
    color: #333333 !important;
}

.title {
    font-weight: 600 !important;
    text-transform: capitalize;
}

.filter-btn {
    text-transform: none !important;
    color: #333333 !important;
    text-decoration: none !important;
    :hover {
        background-color: transparent !important;
    }
}

.filter-btn span {
    font-weight: 500 !important;
    font-size: 0.875rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

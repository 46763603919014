.main-content-data-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-img {
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
    width: 60px;
    height: 60px;
    border: 1px solid #eeeeee;
    margin-top: 4px;
}

.people-card-wrapper {
    padding: 15px !important;
    height: 100% !important;
    box-shadow: none !important;
    min-width: 220px;
    max-width: 420px;
}

.icon-div-user-group-2 {
    height: 16px;
    width: 16px;
    border-radius: 99px;
    /* border: 1px solid #EEEEEE; */
    cursor: pointer;
}

.icon-div-user-group-2 svg {
    height: 7px;
    width: 7px;
}

.chip-label-div-user {
    height: auto !important;
    background-color: #f9f9f9 !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
}

.chip-label-div-user span {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 9px !important;
    line-height: 10px !important;
    color: #333333 !important;
    opacity: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px !important;
}

.select__value-container {
    padding: 2px 5px !important;
}

.folder-name-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #333333;
}

/* Blink effect */
.blink-effect {
    animation: blink 1s ease-in-out 3;
}

@keyframes blink {
    0% {
        background-color: #660c601a;
    }

    50% {
        background-color: transparent;
    }

    100% {
        background-color: #660c601a;
    }
}

.breadcrumb-text-1 {
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 38px !important;
    color: #333333 !important;
    opacity: 30% !important;
}

.breadcrumb-text-2 {
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 38px !important;
    color: #333333 !important;
}

.title {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 18px !important;
}

.filter-btn {
    text-transform: none !important;
    color: #333333 !important;
    text-decoration: none !important;

    :hover {
        background-color: transparent !important;
    }
}

.filter-btn span {
    font-weight: 500 !important;
    font-size: 0.875rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.people-card-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.filtered-layout {
    margin: 0 -8px;
    /* Adjust as needed */
}

.default-layout {
    justify-content: flex-start;
    margin: 0 -8px;
    /* Adjust as needed */
}

.filtered-layout>.col,
.default-layout>.col {
    padding: 8px;
}

.people-list-container {
    background-color: transparent !important;
    box-shadow: none !important;
}

.people-list-header {
    border-radius: 6px !important;
}

.people-list-header tr th:first-child {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.people-list-header tr th:last-child {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.people-list-header-data {
    font-size: 11px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    text-transform: capitalize;
    padding: 10px 16px !important;
    border: none !important;
    background-color: #eeeeee;
}

.people-list-body tr td {
    border-top: 1px solid #eeeeee !important;
    border-bottom: none !important;
    background-color: #ffffff !important;
    padding: 10px 16px !important;
}

.people-list-body tr {
    td:first-child {
        border-left: 1px solid #eeeeee !important;
    }

    td:last-child {
        border-right: 1px solid #eeeeee !important;
    }
}

.people-list-body tr:first-child {
    td:first-child {
        border-top-left-radius: 6px !important;
    }

    td:last-child {
        border-top-right-radius: 6px !important;
    }
}

.people-list-body tr:last-child {
    border-bottom: 1px solid #eeeeee !important;

    td:first-child {
        border-bottom-left-radius: 6px !important;
    }

    td:last-child {
        border-bottom-right-radius: 6px !important;
    }
}

.action-menu ul {
    padding: 0 0 0 0 !important;
}

// type card releated css
.type-card-wrapper {
    border-radius: 6px !important;
    box-shadow: none !important;
    min-width: 226px;
    border: 1px solid #eeeeee;
    min-height: 334px;
}

.type-card-image {
    height: 140px !important;
    width: 100%;
    object-fit: cover !important;
    cursor: pointer;
}

.type-card-action-btn {
    height: 12px !important;
    width: 3px !important;
    position: absolute !important;
    top: 8px;
    right: 8px;
    color: #eeeeee !important;
}

.type-card-content {
    padding: 15px !important;

    .vstack {
        gap: 15px !important;

        .hstack {
            gap: 5px !important;
        }
    }
}

.type-card-content-avatar {
    height: 24px !important;
    width: 24px !important;
    border-radius: 999px;
    object-fit: cover;
    border: 1px solid #eeeeee;
}

.type-card-username-text {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-transform: "capitalize";
}

.type-card-title-text {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    min-height: 40px;
}

.type-card-tag-stack {
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 5px;
}

.type-card-more-tag-badge {
    background-color: #f9f9f9;
    color: #959595;
    border-radius: 1px;
    border: 1px solid #eeeeee;
    padding: 3px 3px;
    font-size: 9px;
    font-weight: 500;
    line-height: 10px;
    display: inline-block;
    vertical-align: middle;
}

.type-card-like-comment-stack {
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
}

.type-card-like-count {
    min-width: 26px;
    min-height: 24px;
    border-radius: 3px;
    padding: 0 5px;
    background-color: #f9f9f9f9;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    color: #959595;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-delete-item,
.action-delete-icon,
.action-delete-label {
    color: #e1593b !important;
}

//type list related css

.type-list-container {
    background-color: transparent !important;
    box-shadow: none !important;
}

.type-list-header {
    border-radius: 6px !important;
}

.type-list-header-2 {
    border-radius: 6px !important;
}

.type-list-header tr th:first-child {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    width: 38%;
}

.type-list-header-2 tr th:first-child {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.type-list-second-header {
    width: 42%;
}

.type-list-header tr th:last-child {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.type-list-header-data {
    font-size: 11px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    text-transform: capitalize;
    padding: 10px 16px !important;
    border: none !important;
    background-color: #eeeeee !important;
}

.type-list-header-2 tr th:last-child {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}


.type-list-body tr td {
    border-top: 1px solid #eeeeee !important;
    border-bottom: none !important;
    background-color: #ffffff !important;
    padding: 10px 16px !important;
}

.type-list-body tr {
    td:first-child {
        border-left: 1px solid #eeeeee !important;
    }

    td:last-child {
        border-right: 1px solid #eeeeee !important;
    }
}

.type-list-body tr:first-child {
    td:first-child {
        border-top-left-radius: 6px !important;
    }

    td:last-child {
        border-top-right-radius: 6px !important;
    }
}

.type-list-body tr:last-child {
    border-bottom: 1px solid #eeeeee !important;

    td:first-child {
        border-bottom-left-radius: 6px !important;
    }

    td:last-child {
        border-bottom-right-radius: 6px !important;
    }
}

.type-list-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.type-list-title-icon {
    font-size: 14px;
    font-weight: 900;
    line-height: 14px;
    color: #660c60;
}

.type-list-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.type-list-createdby {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: #333333 !important;
    gap: 5px;
}

.type-list-created-avatar {
    border: 1px solid #eeeeee;
    height: 24px !important;
    width: 24px !important;
    font-size: 12px !important;
}

.add-new-popover .MuiPaper-root {
    margin: 10px 0 0 0;
}

.new-icon {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
    color: #660c60 !important;
}

.new-text span {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.94px !important;
    color: #959595 !important;
}
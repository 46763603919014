.main-content-wrapper {
  padding-top: 141px;
  padding-bottom: 30px;

  @media(max-width:991.98px) {
    padding-top: 111px;
  }

  .container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .add-new-btn {
    max-width: 270px;
    margin-bottom: 60px;

    @media(max-width:991.98px) {
      margin-bottom: 30px;
    }
  }
}

.news-card-wrapper {

  // height: 100%;
  height: 100%;

  .news-date {
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      letter-spacing: 0px;
      color: #000000;
    }
  }

  .news-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
    overflow: hidden;
  }

  .news-para {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.no-data {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.no-data-img {
  height: 200px;

  @media(max-width:767.98px) {
    height: 120px;
  }
}

.btn-view-article {
  background: #F9F9F9 !important;
  color: #660C60 !important;
  border: none !important;
  text-transform: math-auto !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  padding: 10px !important;

  @media(max-width:350px) {
    width: 100%;
  }
}

.btn-view-article svg {
  font-size: 12px !important;
}

.btn-delete-article {
  background: #F9F9F9 !important;
  color: red !important;
  border: none !important;
  text-transform: math-auto !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  padding: 10px !important;

  @media(max-width:350px) {
    width: 100%;
  }
}

.btn-delete-article svg {
  font-size: 12px !important;
}

.main-content-wrapper-2 {
  padding-top: 110px;
  padding-bottom: 30px;

  @media(max-width:991.98px) {
    padding-top: 111px;
  }

  .container {
    padding-left: 50px;
    padding-right: 50px;
  }

}

.main-content-wrapper-3 {
  padding-top: 0px;
  padding-bottom: 0px;

  @media(max-width:991.98px) {
    padding-top: 0px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

}

.add-title-div {
  display: flex;
  // justify-content: space-between;
  gap: 6px;
  padding: 5px !important;
  width: 100%;
}

.add-title-div .MuiStack-root {
  width: 100%;
}

.add-title-div-2 {
  // display: flex;
  // justify-content: space-between;
  gap: 15px;
  padding: 5px !important;
  width: 90%;
}

.add-title-div-btn {
  padding-top: 9px !important;
  padding-bottom: 5px !important;
  width: 10% !important;
}

.add-title-div-btn-2 {
  margin: auto !important;
  width: 10%;
}

.no-data-img-2 {
  height: 150px;
}

.add-att-tab .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root {
  min-width: max-content !important;
}
/* Custominput-filedInput.css */

/* Styles for the entire input-filedInput wrapper */
.input-field-update {
    border-radius: 3px;
    border: 1px solid #eeeeee;
    padding: 16.5px 14px;

    &:focus-visible {
        outline: none;
        border: 1px solid #eeeeee;
    }

    box-shadow: 0px 0px 0px 1px #0000004a;
    min-height: 120px;
}

.input-field-update textarea,
.input-field-update input {
    padding: 6px 14px !important;
    border: none !important;
    outline: none !important;
    margin: 10px !important;
    max-height: 100px !important;
    overflow: auto !important;
    width: 97% !important;
}

.input-field-update textarea:focus,
.input-field-update input:focus {
    border: none !important;
    outline: none !important;
}

/* Main input container in the input-filedInput */
.input-filed__input {
    border: none !important;
    outline: none !important;
    min-height: 65px !important;
    max-height: 65px !important;
    border-radius: 3px;
    padding: 6px 14px !important;
    background-color: #fafafa;
    margin: 10px !important;
    width: 97% !important;
}

/* Highlighter styles */
.input-field-update__highlighter {
    padding-left: 2px;
    padding-right: 2px;
    max-height: 56px !important;
}

.input-field-update__suggestions {
    width: max-content;
    max-height: 180px;
    overflow: auto;
}

/* Suggestions list styling */
.input-filed__suggestions__list {
    background-color: "white";
    border: "1px solid rgba(0,0,0,0.15)";
    font-size: 14;
}

/* Individual suggestion item */
.input-filed__suggestions__item {
    padding: "5px 15px";
    border-bottom: "1px solid rgba(0,0,0,0.15)";
}

/* Focused suggestion item */
.input-filed__suggestions__item--focused {
    background-color: "#cee4e5";
}

.cate-update-post .select__menu {
    width: 95% !important;
}
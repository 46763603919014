.icon-preview-box {
    display: flex;
    gap: 12px;
    border-radius: 6px;
    padding: 15px;
}

.link-label-list-grid {
    border-right: 1px solid #eeeeee;
    height: auto;
    overflow: auto;
    padding-left: 0 !important;
    max-height: 80vh;
    scrollbar-width: none;
}

.link-selected-type-border-right {
    border-right: 1px solid #660c60;
}

.link-label-icon {
    font-size: 12px;
    color: #959595 !important;
    font-weight: 900;
    line-height: 12px;
}

.link-label-icon-selected {
    font-size: 12px;
    color: #660c60;
    font-weight: 900;
    line-height: 12px;
}

.link-label-name span {
    font-size: 14px;
    font-weight: 400;
    color: #959595;
    line-height: 16px;

    &::first-letter {
        text-transform: capitalize;
    }
}

.link-label-name-selected span {
    font-size: 14px;
    font-weight: 400;
    color: #660c60;
    line-height: 16px;

    &::first-letter {
        text-transform: capitalize;
    }
}

.link-icon-box {
    display: flex;
    gap: 12px;
    border-radius: 6px;
    padding: 15px;
}

.icon-content {
    display: flex;
    gap: 12px;
    overflow: hidden;
}

.link-label-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 22px;
}

@media (max-width: 900px) {
    .link-label-list-grid {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        border-right: none;
        border-bottom: 1px solid #eeeeee;
        white-space: nowrap;
        margin-left: 16px !important;
    }

    .list-item-menu-links {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        /* Adjust spacing between items */
    }

    .link-label-list-grid .list-item-menu-links .MuiListItem-root {
        min-width: auto;
        width: auto;
        /* Adjust item width for mobile */
    }

    .link-selected-type-border-right {
        border-right: none;
        border-bottom: 1px solid #660c60;
    }
}
.tbl-row-attribute-row-2 {
    background-color: #f9f9f9 !important;
    border: 1px solid #eeeeee !important;
    // padding: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 6px !important;
}

.menu-items-cell {
    padding: 10px !important;
}

.menu-link-chip {
    background-color: #FFFFFF !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 3px !important;
}

.menu-items-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.attribute-col-lg-12 {
    margin-top: 10px !important;
}

.titile-field-value {
    font-size: 11px !important;
    line-height: 11px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.title-field-default {
    font-size: 11px !important;
    line-height: 11px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.tbl-cell-head-3 {
    font-family: "Inter" !important;
    font-weight: 700 !important;
    font-size: 11px !important;
    line-height: 11px !important;
    letter-spacing: 1px !important;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: none !important;
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background: none !important;
    color: #333333 !important;
    min-width: 70px;
}

.tbl-row-attribute-row {
    background-color: #f9f9f9 !important;
    border: 1px solid #eeeeee !important;
    padding: 10px !important;
    border-radius: 6px !important;
}

.tbl-cell-attribute {
    border-bottom: none !important;
    padding: 10px !important;
}

.text-filed-attribute .MuiInputBase-root input {
    background-color: #ffff !important;
}

.text-filed-attribute .MuiOutlinedInput-notchedOutline {
    border-radius: 3px !important;
    border: 1px solid #EEEEEE !important;
}

.attribute-td-icon {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 12px !important;
    color: #333333 !important;
    opacity: 20% !important;
}

.add-option-btn {
    border: 1px solid #660C60 !important;
    color: #660C60 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14.52px !important;
    max-width: 200px;
    width: 100%;
}

.repeatable-col {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.select-field-type {
    font-size: 14px !important;
    line-height: 16.94px !important;
}

.tbl-body-cell {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.tbl-body-cell-3 {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
    background-color: #fff !important;
}

.tbl-body-cell-4 {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    background-color: #fff !important;
}

.tbl-body-cell-4 svg {
    padding-left: 20px !important;
}

.check-icon {
    color: #45B733 !important;
}

.cross-icon {
    color: red;
}

.col-status {
    display: flex;
    justify-content: center !important;

    @media(max-width:997px) {
        margin-top: 10px !important;
    }
}

.select-fields-type-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width:991.98px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .field-wrapper {
        max-width: calc(100% - 181px);
        width: 100%;

        @media(max-width:991.98px) {
            max-width: 100%;
        }
    }
}

.search-changelog {
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    background: none;
    border: none;
}


.export-csv-btn {
    border: 1px solid #660C60 !important;
    color: #660C60 !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    // line-height: 14.52px !important;
    height: 40px;
    width: 100%;
}

.search-changelog-icon {
    color: #333333;
}

.changelog-table-div {
    background: none !important;
    color: none;
    border-radius: 4px;
    box-shadow: none !important;
    width: 100%;
    margin-bottom: 30px;
}

.tbl-changelog {
    background-color: #f9f9f9 !important;
    border: 1px solid #eeeeee !important;
    height: 42px !important;
    margin-top: 47px !important;

}

.tbl-cell-5 {
    padding: 10px 0 10px 15px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
}

.extra-row {
    height: 9px;
}

.tbl-cell-2 {
    border-bottom: none !important;
}

.changelog-body {
    padding: 30px !important;
}

.tbl-cell-3 {
    border-bottom: none !important;
    padding-left: 0 !important;
}

.MuiTablePagination-root {
    .MuiToolbar-regular {
        padding-left: 0 !important;
        gap: 24px;
        justify-content: space-between;

        p {
            color: #959595;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;

            span {
                font-weight: 700;
            }
        }

        .MuiTablePagination-spacer {
            display: none;
        }

        .MuiTablePagination-input {
            display: none;
        }

        .MuiTablePagination-actions {
            padding-left: 0;
            margin-left: 0;
            gap: 8px;
            display: flex;


            .MuiIconButton-root {
                border: 1px solid var(--primary-color);

                svg {
                    path {
                        color: var(--primary-color);
                    }
                }

                &.Mui-disabled {
                    border: 0;

                    svg {
                        path {
                            color: var(--textgrey);
                        }
                    }
                }
            }
        }
    }
}

.search-changelog-input {
    input {
        padding-right: 70px;
    }
}

.search-changelog:hover {
    background: none !important;
}

.csv-btn-a {
    text-decoration: none !important;
}

.publish-div {
    @media(max-width:769px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 12px
    }
}

.enable-div {
    @media(max-width:769px) {
        margin-top: 0 !important;
    }
}

.tbl-row-attribute-row+.tbl-row-attribute-row {
    margin-top: 10px;
    /* Adjust the value as needed */
}

.action-btn-icon-btn {
    padding: 0 !important;
}

@media(max-width:1024px) {
    .csv-btn-a {
        flex: 1 !important;
    }

    .csv-btn-a button {
        max-width: 100% !important;
    }
}
// @import "rsuite/dist/rsuite.css";
@import "../assets/fonts/fonts.css";

// Color Variables //
:root {
  --body-bg: #f9f9f9;
  --light-bg: #f7f7f7;
  --primary-color: #660c60;
  --dark-color: #333333;
  --white-color: #ffffff;
  --textgrey: #999999;
  --border: #eeeeee;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 6;
}

::-webkit-scrollbar-thumb {
  border-radius: 6;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--body-bg);
}

* {
  font-family: "Inter" !important;
}

.primary-text {
  color: var(--primary-color);
}

.dark-text {
  color: var(--dark-color);
}

.para-text {
  color: var(--textgrey);
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

// Common Styles //
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.btn {
  border-radius: 3px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
}

.icon-btn {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--primary-color);
  padding: 0;
  width: 36px;
  height: 36px;
  min-width: 36px !important;
}

.primary-btn {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--primary-color);
  border-radius: 6px !important;
  text-transform: capitalize !important;
}

.publish-btn {
  padding: 10px 15px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
}

.secondary-btn {
  background-color: var(--white-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;

  &:hover,
  &:focus {
    border: 1px solid var(--primary-color);
    background-color: var(--white-color) !important;
  }
}

.ternary-btn {
  border-radius: 6px !important;
  background-color: #f9f9f9 !important;
  color: #333333 !important;
  border: 1px solid #eeeeee;

  &:hover,
  &:focus {
    border-radius: 6px !important;
    background-color: #f9f9f9 !important;
    color: #333333 !important;
    border: 1px solid #eeeeee;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 30px;
}

.card {
  border: 1px solid var(--border);
  border-radius: 6px;
  box-shadow: none;
  background-color: var(--white-color);

  .card-img-top {
    height: 133px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px 6px 0 0;
  }

  .card-body {
    padding: 15px;
  }
}

.form-label {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

.form-control {
  &::-webkit-input-placeholder {
    color: var(--textgrey);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Inter";
  }

  &::-moz-placeholder {
    color: var(--textgrey);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Inter";
  }

  &::-ms-input-placeholder {
    color: var(--textgrey);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Inter";
  }

  &:focus {
    color: var(--dark-color);
    background-color: transparent;
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: none;
  }
}

.gap-10 {
  gap: 10px;
}

.gap-30 {
  gap: 30px;
}

.p-15 {
  padding: 15px !important;
}

.custom-checkbox {
  padding: 6px 5px;
  border: 1px solid #fcf1d4;
  background-color: #fcf4df;
  border-radius: 6px;
  background-color: #f3b81f;
  padding: 10px;

  &.active-checkbox {
    border: 1px solid #dfffd4;
    background-color: #45b733;

    .checkbox-style {
      color: #58a63d;
    }
  }
}

.checkbox-style {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0px;
  border-radius: 6px;

  color: #ffffff !important;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      &~.checkmark {
        background-color: #ffffff;
        border-color: #ffffff;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 0px;
      width: 5px;
      height: 8px;
      border: solid #45b733;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.input-field {
  .MuiOutlinedInput-root {
    border-radius: 6px;
    border: 1px solid #eeeeee !important;
    background-color: #f7f7f7 !important;

    &:focus-visible {
      outline: none !important;
      border: 1px solid #eeeeee !important;
    }

    &.Mui-focused {
      fieldset {
        border-width: 1px !important;
        border: 1px solid #eeeeee !important;
      }
    }

    &.Mui-disabled {
      color: var(--dark-color);
      background-color: var(--light-bg);

      input {
        -webkit-text-fill-color: var(--dark-color);
      }
    }
  }

  background-color: #f7f7f7 !important;

  div input {
    padding: 9px 14px !important;
    /* padding: 10px 15px 10px 15px; */
    background-color: #f7f7f7;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    opacity: 50%;
  }

  input {
    padding: 9px 14px !important;
    /* padding: 10px 15px 10px 15px; */
    background-color: #f7f7f7;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    opacity: 50%;
  }
}

.error-msg {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.muilt-select-field {
  .select__control {
    border-radius: 6px;
    min-height: 40px;
    padding: 2px;
    background-color: #f7f7f7 !important;

    &:hover,
    &:focus,
    &.select__control--is-focused {
      border-color: #eeeeee !important;
      box-shadow: none;
    }

    .select__multi-value {
      border-radius: 6px;
      // height: 24px;
      padding: 6px;
      color: var(--textgrey);
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: #f7f7f7 !important;
    }
  }
}

.select__indicator-separator {
  display: none;
}

.ck-editor {
  .ck-editor__top {
    .ck-sticky-panel {
      .ck-toolbar {
        background: var(--light-bg) !important;
        border-radius: 6px 6px 0 0 !important;
      }
    }
  }

  .ck-content {
    border-radius: 0 0 6px 6px !important;
  }

  .ck-toolbar__separator {
    display: none !important;
  }

  .ck-dropdown__button {
    border: 1px solid var(--border) !important;
    background-color: var(--white-color) !important;
    border-radius: 4px !important;

    @media (max-width: 768.98px) {
      width: 100% !important;
    }
  }

  .ck-focused {
    &:focus {
      border-color: #ccced1 !important;
      box-shadow: none !important;
    }
  }

  .ck-toolbar>.ck-toolbar__items {
    gap: 20px !important;
    padding: 6px !important;
    border-bottom: 1px solid #eeeeee;
    background-color: #ffffff !important;

    @media (max-width: 991.98px) {
      gap: 0 !important;
    }
  }

  .ck-content.ck-editor__editable {
    min-height: 130px !important;
  }
}

.ck-editor__editable_inline {
  background-color: #f7f7f7 !important;
}

.ck-sticky-panel {
  .ck-button {
    &:focus {
      box-shadow: none !important;
    }

    svg {
      path {
        fill: #660c60 !important;
      }
    }

    &.ck-on {
      background: var(--primary-color) !important;

      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }
}

.ck-heading-dropdown {
  >.ck-button {
    &:focus {
      box-shadow: none !important;
    }

    &.ck-on {
      background: var(--white-color) !important;
      color: var(--primary-color) !important;
    }

    color: var(--primary-color) !important;
    background: var(--white-color) !important;
  }
}

.muilt-select-field div {
  border-radius: 6px;
}

.dialog-content {
  overflow: hidden;
}

.required {
  color: red;
  margin: 0 0 0 6px;
}

.cursor-pointer {
  cursor: pointer;
}

.tbl-cell-head {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid #eeeeee !important;
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 0 !important;
  background: none !important;
  color: #333333 !important;
  min-width: 70px !important;
}

.tbl-cell-2 {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid #eeeeee !important;
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 0 !important;
  background: none !important;
  color: #333333 !important;
  min-width: 70px;
}

.type-list-paper {
  background: none !important;
  color: none;
  border-radius: 4px;
  box-shadow: none !important;
  width: 100%;
  margin-bottom: 16px;
}

.type-list-paper-2 {
  background: none !important;
  color: none;
  border-radius: 4px;
  box-shadow: none !important;
  width: 100%;
  // margin-bottom: 16px;
}

.type-list-paper-2 ul {
  padding: 5px 15px 0 15px;
}

.other-color {
  color: #333333 !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
}

.accordion-button {
  font-weight: 700 !important;
  font-size: 12px !important;
  background-color: #fff !important;
  line-height: 14.52px !important;
  color: #660c60 !important;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
}

.font-inter {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16.94px !important;
}

.font-inter span {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #660c60 !important;
}

.mandatory-icon {
  color: #959595 !important;
}

.tbl-head-class-2 {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid #eeeeee !important;
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: none !important;
  color: #660c60 !important;
  min-width: 70px;
}

.tbl-head-class-global {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid #eeeeee !important;
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff !important;
  color: #660c60 !important;
  min-width: 70px;
}

.pl-35 {
  padding-left: 35px !important;
}

.main-content-wrapper.drawer-close {
  width: calc(100% - 85px);
  margin-left: 85px;
}

.MuiButtonBase-root list-icon-root-false-2 {
  margin-right: 13px;
}

.MuiButtonBase-root .list-icon-root-false {
  margin-right: 0px;
}

.menu-name-false {
  display: none;
}

.MuiButtonBase-root .sub-menu-icon-false {
  margin-left: 0;
}

// .drawer .MuiPaper-elevation {
//     padding: 20px;
// }

// .drawer .MuiPaper-root {
//     z-index: 8 !important;
// }

.false .MuiDrawer-paper {
  padding: 20px;
}

.false .MuiDrawer-paper {
  z-index: 8 !important;
}

.short-drawer-width .MuiDrawer-paper {
  padding: 20px;
}

.short-drawer-width .MuiDrawer-paper {
  z-index: 8 !important;
}

.list-item-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.list-item-menu-folders {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.short-drawer-width .MuiPaper-elevation {
  /* width: calc(64px + 1px); */
  width: 107px !important;
}

.open-selected-menu {
  border-radius: 100% !important;
}

.sub-menu-for-close {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.w-48px {
  min-width: 48px;
}

.tab-box {
  padding: 24px 0;
}

.font-inter .Mui-checked {
  color: #333333;
  opacity: 50%;
}

.accordion {
  --bs-accordion-btn-icon-width: 12px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(360deg) !important;
}

.accordion-button::after {
  transform: rotate(180deg) !important;
}

.accordion-button::after {
  width: var(--bs-accordion-btn-icon-width);
  height: 12px;
}

.small-screen {
  width: 100% !important;
  margin-left: 0px !important;
}

.small-screen .small-screen-container {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.delete-btn-model {
  background-color: #f9f9f9 !important;
  color: #e1593b !important;
  border: 1px solid #f9f9f9;

  @media (max-width: 425px) {
    width: 100%;
  }
}

.delete-btn-model:focus-visible {
  border-color: #f9f9f9 !important;
}

.delete-btn-model:active {
  border-color: #f9f9f9 !important;
}

.delete-btn-model:hover {
  border-color: #f9f9f9 !important;
}

.dialog-title {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  color: #333333 !important;
}

.model-div-height {
  height: 220px !important;
  overflow: auto;
}

.action-btn-model {
  @media (max-width: 425px) {
    display: flex;
    justify-content: center !important;
    flex-direction: column-reverse !important;
    gap: 10px;
  }
}

.warning-alert {
  background-color: #f3b81f !important;
  height: 60px !important;
  align-items: center !important;
}

.warning-alert .MuiAlert-icon {
  padding: 19px !important;
  background: #f39e1f;
  /* opacity: 41%; */
}

.warning-alert-icon {
  color: #ffffff;
}

.warning-alert .MuiAlert-message {
  padding: 19px 19px 19px 0 !important;
  color: #ffffff;
}

.container .MuiPaper-root {
  padding: 0 !important;
}

.MuiAlert-action {
  padding: 0 !important;
  margin-left: auto;
  margin-right: 10px !important;
}

.MuiAlert-action button {
  color: #ffffff;
}

.success-alert {
  background-color: #45b733 !important;
  height: 60px !important;
  align-items: center !important;
}

.success-alert .MuiAlert-icon {
  padding: 19px !important;
  background: #b7883333;
  /* opacity: 41%; */
}

.success-alert-icon {
  color: #ffffff;
}

.success-alert .MuiAlert-message {
  padding: 19px 19px 19px 0 !important;
  color: #ffffff;
}

.alert-close-btn {
  // background-color: #f9f9f9 !important;
  color: #333333 !important;
}

.tbl-cell-head-4 {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  border-bottom: none !important;
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 2;
  background: none !important;
  color: #333333 !important;
  min-width: 70px;
}

.tbl-cell-head-5 {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: none !important;
  color: #660c60 !important;
  min-width: 70px;
}

.fs-14-muted {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "Inter" !important;
  color: #959595 !important;
}

.fs-12-muted {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.52px !important;
  color: #959595 !important;
}

.z-index-100 {
  z-index: 100;
}

.z-index-101 {
  z-index: 101;
}

.input-field-group {
  .MuiOutlinedInput-root {
    border-radius: 3px;
    border: 1px solid #eeeeee !important;

    &:focus-visible {
      outline: none !important;
    }

    &.Mui-focused {
      fieldset {
        border-width: 1px !important;
        border-color: #eeeeee !important;
      }
    }

    &.Mui-disabled {
      color: var(--dark-color);
      background-color: var(--light-bg);

      input {
        -webkit-text-fill-color: var(--dark-color);
      }
    }
  }
}

.admin-view-div {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 0px, 25px, 0px, 0px;
  background-color: #f9f9f9;
}

.admin-view-div-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 58px;
  border-radius: 3px;
  padding: 19px;
  background-color: #eeeeee;
}

.admin-view-div-icon svg {
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  color: #333333;
}

.admin-view-div-p {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.admin-view-div-p p {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.MuiAvatarGroup-avatar {
  height: 24px !important;
  width: 24px !important;
  font-size: 12px !important;
}

.add-user-content-p-0 {
  padding: 0 !important;
}

.tbl-cell-head-contact {
  font-family: "Inter" !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  letter-spacing: 1px !important;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: none !important;
  text-align: left;
  padding: 10px 2px !important;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: none !important;
  color: #333333 !important;
  min-width: 70px;
}

.drp-buttons {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.btn-save-draft {
  background: #6c757d !important;
  font-size: 14px !important;
}

.btn-save-draft svg {
  font-size: 14px !important;
}

.btn-save-draft span {
  font-size: 14px !important;
}

.fs-14-white {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "Inter" !important;
  color: #fff !important;
}

.opacity-50 {
  opacity: 50%;
}

.type-pagination .MuiPagination-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #660c60 !important;

  @media (max-width: 481px) {
    gap: 0;
  }
}

.type-pagination .MuiPagination-ul li button {
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #660c60;
}

.pagination-btns {
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #333333 !important;
}

.type-pagination .MuiPagination-ul li .Mui-selected {
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #ffffff;
  background-color: #660c60 !important;
}

.fs-12-pagination {
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #660c60 !important;
}

.fs-14-600 {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.fs-14-700 {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #333333 !important;
}

.fs-12-700 {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #333333 !important;
}

.fs-12 {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.main-grid-post {
  align-items: flex-start !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.main-grid-1 {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.main-content-div-1-view-post {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.att-card-body {
  padding: 30px !important;
}

.fs-12-action-btn {
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  line-height: 15px !important;
  color: #959595 !important;
}

.fs-9 {
  font-size: 9px !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.fs-10 {
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.fs-20 {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.tbl-cell-people-group-1 {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #333333 !important;
  text-transform: capitalize !important;
  border-bottom: none !important;
}

.fs-14-primary {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #660c60 !important;
}

.fs-14-bold {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #333333 !important;
}

.fs-15-bold {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #333333 !important;
  line-height: 20px !important;
}

.another-create-content-popup .MuiPaper-elevation {
  // position: absolute !important;
  // top: 75px !important;
}

.tbl-menu-item-text-icon {
  color: #660c60 !important;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.tbl-menu-item-text {
  color: #959595 !important;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.chat-users-list {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #1f2542 !important;
}

.chat-popup-popover .MuiPaper-elevation {
  top: 100px !important;
}

.add-menu-content-p-0 {
  padding: 0 !important;
}

#gjs {
  border: none;
}

.gjs-one-bg {
  background-color: #78366a;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: rgba(255, 255, 255, 0.7);
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: #ec5896;
  color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #ec5896;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block-c {
  width: auto;
  height: auto;
  min-height: auto;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}

.panel__basic-actions {
  position: initial;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 300px;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}

.panel__switcher {
  position: initial;
}

.panel__devices {
  position: initial;
}

:root {
  --gjs-primary-color: #78366a;
  --gjs-secondary-color: rgba(255, 255, 255, 0.7);
  --gjs-tertiary-color: #ec5896;
  --gjs-quaternary-color: #ec5896;
}

// .container-class {
//     width: 100%;
//     height: 500px;
// }

// ---cms style starts here
.clearfix {
  clear: both;
}

.header-banner {
  padding-top: 35px !important;
  padding-bottom: 100px !important;
  color: #ffffff;
  font-family: Helvetica, serif;
  font-weight: 100;
  // background-image: url("/assets/images/demos/bg-gr-v.png"), url("/assets/images/demos/work-desk.jpg");
  background-attachment: scroll, scroll;
  background-position: left top, center center;
  background-repeat: repeat-y, no-repeat;
  background-size: contain, cover;
}

.container-width {
  width: 90%;
  max-width: 1150px;
  margin: 0 auto;
}

.logo-container {
  float: left;
  width: 50%;
}

.logo {
  background-color: #fff;
  border-radius: 5px;
  width: 130px;
  padding: 10px;
  min-height: 30px;
  text-align: center;
  line-height: 30px;
  color: #4d114f;
  font-size: 23px;
}

.menu {
  float: right;
  width: 50%;
}

.menu-item {
  float: right;
  font-size: 15px;
  color: #eee;
  width: 130px;
  padding: 10px;
  min-height: 50px;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
}

.lead-title {
  margin: 150px 0 30px 0;
  font-size: 40px;
}

.sub-lead-title {
  max-width: 650px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #c6c6c6;
}

.lead-btn {
  margin-top: 15px;
  padding: 10px;
  width: 190px;
  min-height: 30px;
  font-size: 20px;
  text-align: center;
  letter-spacing: 3px;
  line-height: 30px;
  background-color: #d983a6;
  border-radius: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.lead-btn:hover {
  background-color: #ffffff;
  color: #4c114e;
}

.lead-btn:active {
  background-color: #4d114f;
  color: #fff;
}

.flex-sect {
  background-color: #fafafa;
  padding: 100px 0;
  font-family: Helvetica, serif;
}

.custom-menu {
  padding: 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 320px;
}

.custom-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.custom-menu-item {
  margin-bottom: 8px;
}

.custom-menu-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
  font-size: 16px;
}

.custom-menu-icon {
  font-size: 18px;
  color: #660c60;
}

.custom-menu-name {
  font-weight: 400;
}

.gjs-btn-save {
  background-color: #007bff;
  /* Blue background */
  color: white;
  /* White text */
  border: none;
  /* Remove borders */
  border-radius: 4px;
  /* Rounded corners */
  padding: 5px 10px;
  /* Padding for the button */
  cursor: pointer;
  /* Pointer cursor on hover */
  font-size: 14px;
  /* Font size for the label */
  text-align: center;
  /* Center-align text */
}

.gjs-btn-save:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.cke_notification {
  display: none !important;
}

.block-title {
  text-align: center;
  font-size: 1.5em;
  /* Adjust font size if needed */
  margin: 10px 0;
  /* Optional spacing */
}

.block-content {
  padding: 10px !important;
}

.gutter {
  --bs-gutter-x: 15px;
  --bs-gutter-y: 15px;
}

.custom-feed-block {
  padding: 10px 15px 10px 15px;
}

.w-33 {
  width: 33% !important;
}

.create-content-btn {
  // width: auto !important;
  background-color: #660c60 !important;
  color: #ffff !important;
  border-color: #ffff !important;

  @media (max-width: 991px) {
    display: none;
  }
}

.create-content-btn-open-false {
  display: none;
}

.create-content-btn-icon {
  display: none;
}

.create-content-btn-icon-open-false {
  display: block;
}

.setting-btn {
  width: auto !important;
  background-color: #fff !important;
  color: #959595 !important;
  border-color: #ffff !important;
}

.setting-btn-open-false {
  width: auto !important;
  background-color: #fff !important;
  color: #959595 !important;
  border-color: #ffff !important;
}

.content-btn {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.content-btn-open-false {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.setting-icon {
  color: #959595 !important;
}

.logged-user-name {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.icon-div-selected {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #660c60;
  border-radius: 99px;
}

.icon-div-selected svg path {
  color: #ffffff;
}

.icon-div-2 {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fs-12-660C60 {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #660c60 !important;
}

.author-avtar-creator-3 {
  height: auto !important;
  width: 40px !important;
  min-height: 40px;
  font-size: 18px !important;
}

.author-avtar-creator-3 img {
  height: auto !important;
  width: 24px !important;
  min-height: 24px;
}

.author-avtar-creator-4 {
  height: auto !important;
  width: 24px !important;
  min-height: 24px;
  font-size: 14px !important;
}

.author-avtar-creator-4 img {
  height: auto !important;
  width: 24px !important;
  min-height: 24px;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.upload-img-wrapper-3 {
  background-color: #f9f9f9 !important;
  border-radius: 6px;
  border: 1px solid #eeeeee;
}

.media-details-div {
  padding: 15px;
}

.media-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.media-size {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #333333 !important;
  opacity: 50% !important;
}

.remove-img {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.52px !important;
  color: #959595 !important;
}

.doc-file-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eeeeee;
}

.doc-file-icon {
  font-size: 24px;
  color: #660c60;
}

.select__placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16.94px !important;
  color: #333333 !important;
  opacity: 50% !important;
}

.radio-group-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16.94px !important;
  color: #333333 !important;
  opacity: 50% !important;
  margin-left: 0 !important;
  gap: 5px;
}

.tooltip-desc-class .MuiTooltip-tooltip {
  background-color: #333333 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  padding: 5px 10px !important;
}

.card-wrapper {
  border: 1px solid #eeeeee !important;
  border-radius: 6px !important;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 700px !important;
}

.carousel-item {
  height: 100% !important;
}

.select__single-value {
  color: #333333 !important;
  opacity: 50% !important;
}

.input-field .MuiInputBase-root textarea {
  color: #333333 !important;
  opacity: 50% !important;
}

.radio-group-label .MuiButtonBase-root {
  color: #333333 !important;
  opacity: 50% !important;
}

.save-draft-btn {
  background-color: #fff !important;
  color: #959595 !important;
  border: none !important;
}

.type-list-second-header-att-post {
  width: 34%;
}

.type-list-last-header-att-post {
  text-align: start !important;
}

.status-icon {
  height: 12px;
  width: 3px;
}

.type-post-tab .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
  min-width: auto !important;
}

.type-post-tab .MuiTabs-scroller .MuiTabs-flexContainer {
  border-bottom: 1px solid #eeeeee;
}

.text-area-field {
  background-color: #f7f7f7;
  border-radius: 6px;
  resize: none;
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  // color: #333333;
  color: rgba(51, 51, 51, 0.5);
  /* 50% opacity for text color */
  /* opacity: 50%; */
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

.text-area-field:focus {
  background-color: #f7f7f7;
  border-radius: 6px;
  resize: none;
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  color: rgba(51, 51, 51, 0.5);
  /* opacity: 50%; */
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

.add-att-model .MuiDialog-container .MuiPaper-root {
  max-height: calc(100vh - 200px) !important;
  width: 100% !important;
  max-width: 1200px !important;
}

.author-avtar-type-post {
  height: 24px !important;
  width: 24px !important;
  font-size: 12px !important;
}

.cate-update-post {
  margin-top: 10px !important;
}

.cate-term-list-update-post {
  margin-top: 14px !important;
}

// @media (min-width: 600px) {
//     .MuiDrawer-root .MuiDrawer-paper {
//         width: calc(118px) !important;
//     }
// }
.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 700px !important;
}

.carousel-item {
  height: 100% !important;
}

.carousel-item img {
  object-fit: cover;
}

.new-icon {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  color: #660c60 !important;
}

.new-text span {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16.94px !important;
  color: #959595 !important;
}

.form-ctrl-label {
  margin-left: 0 !important;
}

.radio-acc-type {
  padding: 0 !important;
  margin-right: 5px !important;
}

.MuiInputBase-root fieldset {
  border-color: #eeeeee !important;
}

.repeatable-col-2 {
  margin-top: 10px !important;
}

.checkbox-att span {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.52px !important;
  color: #959595 !important;
  // opacity: 50%;
}

.action-like {
  padding: 5px 10px;
}

.action-comments {
  padding: 5px 10px;
}

.author-avtar-creator-comment {
  height: auto !important;
  width: 24px !important;
  min-height: 24px;
  font-size: 14px !important;
  border: 1px solid #eeeeee !important;
}

.fs-14-muted .MuiTypography-root {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "Inter" !important;
  color: #959595 !important;
}

.body-text-1 {
  font-size: 14px !important;
  text-align: start;
}

@media screen and (max-width: 600px) {
  .body-text-1 {
    font-size: 12px !important;
  }
}

.edit-content-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-content-btn button {
  width: 25% !important;
}

@media screen and (max-width: 768px) {
  .edit-content-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-content-btn button {
    width: 75% !important;
  }
}

.breadcrumb-search-title {
  font-size: 18px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.search-top-box {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.search-result-text-1 {
  font-size: 16x !important;
  font-weight: 600 !important;
  color: #333333 !important;
  line-height: 20px !important;
}

.search-top-box-2 {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.search-result-text-2 {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #959595 !important;
}

.tab-count-data {
  min-height: 20px;
  min-width: 20px;
  max-width: 40px;
  max-height: 40px;
}

.selected-tab-attribute div .tab-count-data {
  background-color: #660C60 !important;
  color: #FFFFFF !important;
}

.non-selected-tab-attribute div .tab-count-data {
  background-color: #959595 !important;
  color: #FFFFFF !important;
}

.search-all-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #660C60 !important;
}

.tab-box-4 {
  padding: 15px 0;
}

.tab-box-4 .type-list-paper {
  padding: 0 15px 15px;
  max-height: 450px !important;
  overflow-y: auto;
}

.tab-box-4 .type-list-paper-global-search {
  padding: 0 15px 15px;
  max-height: calc(100vh - 265px);
  overflow-y: auto;
  box-shadow: none !important;
}

.tab-box-4 .type-list-paper .MuiTableContainer-root {
  overflow-x: clip !important;
}
.bg-img-dashboard {
  background-image: url("../assets/images/dashboard-user.jpg");
  height: 230px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.serach-box {
  // flex: 0 0 40.54%;
}

.search-input {
  border: 1px solid #eeeeee;
  border-radius: 6px !important;
  background-color: #f9f9f9;
  height: auto;
  padding: 10px 54px 10px 15px;
}

.search-input:focus {
  border-color: #eeeeee !important;
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
  z-index: 0 !important;
}

.search-btn-dash {
  // background-color: #ffff !important;
  // border-color: #f9f9f9;
  // width: 38px !important;
  // height: 38px;
  // border-radius: 100px !important;
  // position: absolute !important;
  // right: 0;
  // margin: 4px 5px 4px 4px !important;
  // border: none !important;
}

.search-btn-dash-4 {
  // background-color: #660c60 !important;
  // border-color: #f9f9f9;
  min-width: 38px !important;
  width: 38px !important;
  height: 38px;
  // border-radius: 100px !important;
  position: absolute !important;
  right: 0;
  // margin: 4px 5px 4px 4px !important;
  right: 0;
  top: 3px;
  bottom: 0;
  padding: 0 !important;
}

.search-btn-dash-5 {
  background-color: #660c60 !important;
  border-color: #f9f9f9;
  width: 36px !important;
  height: 36px;
  border-radius: 100px !important;
  // position: absolute !important;
  // right: 0;
  // margin: 4px 5px 4px 4px !important;
  margin-bottom: 10px !important;
}

.search-btn-dash-6 {
  background-color: #660c60 !important;
  border-color: #f9f9f9;
  width: 38px !important;
  height: 38px;
  border-radius: 100px !important;
  position: absolute !important;
  right: 0;
  margin: 4px 5px 4px 4px !important;
}

.search-btn-dash svg {
  color: #660c60;
  font-size: 22px !important;
}

.search-btn-dash-4 svg {
  color: #660c60;
  font-size: 24px !important;
}

.search-btn-dash-5 svg {
  color: #eeeeee;
  font-size: 12px !important;
}

.search-btn-dash-6 svg {
  color: #eeeeee;
  font-size: 12px !important;
}

.content-title {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.content-title-update {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: start;
  color: #333333 !important;
  line-height: 20px !important;
}

.content-desc {
  opacity: 50%;
}

.main-content-div-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  // margin-bottom: 20px;

  @media (max-width: 481px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

@media (min-width: 900px) {
  .update-multi-grid-item .MuiGrid-root {
    padding-top: 15px !important;
  }
}

.main-div-posted {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.icon-bd-div {
  border: 1px solid #eeeeee;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f9f9f9;
}

.icon-bd-div svg {
  color: #660c60 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
}

.main-div-workspace {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.card-type-post-div {
  padding: 0 !important;
}

.main-grid {
  width: 100% !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.main-box-class {
  height: 160px;
  border: 1px solid #eeeeee;
}

.post-img {
  width: 30% !important;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.content-div-body {
  background-color: #f9f9f9 !important;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  max-height: 224px;
  padding: 15px !important;
  width: 70% !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.content-div-body-2 {
  padding: 15px 15px 15px 15px !important;
  width: 100% !important;
  background-color: #fff !important;
}

.card-header-content {
  padding: 10px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.no-border {
  border-top: none !important;
}

.no-border-img-carousel {
  border-top: none !important;
  padding: 0 !important;
}

.hide-option-btn {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #333333 !important;
  border: none !important;
  background-color: #ffffff !important;
}

.hide-option-btn-svg {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #333333 !important;
  background-color: transparent !important;
}

.att-col-12 {
  margin-top: 0 !important;
}

.author-avtar-creator img {
  height: 32px;
  width: 32px;
}

.search-btn-dash-2 {
  background-color: #660c60 !important;
  border-color: #f9f9f9;
  width: 38px !important;
  height: 38px;
  border-radius: 100px !important;
  position: absolute !important;
  right: 0;
  margin: 0px 4px 0px 4px;
  top: -2px;
}

.search-btn-dash-3 {
  background-color: #660c60 !important;
  border-color: #f9f9f9;
  width: 20px !important;
  height: 20px;
  border-radius: 150px !important;
  position: absolute !important;
  right: 0;
  margin: 0px 4px 0px 4px;
}

.card-action-2 {
  flex-direction: column !important;
  gap: 10px;
}

.card-action-3 {
  align-items: flex-start !important;
  gap: 10px;
  flex-direction: column !important;
}

.comment-box {
  border: 1px solid #eeeeee !important;
  background-color: #f9f9f9 !important;
  padding: 10px;
  border-radius: 6px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  width: max-content;
}

.fa-like-btn {
  width: 16px !important;
  height: 16px !important;
  border-radius: 99px !important;
  background-color: #1d3557 !important;
  padding: 5px !important;
}

.fa-like-btn path {
  font-size: 7px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.fa-love-btn {
  width: 16px !important;
  height: 16px !important;
  border-radius: 99px !important;
  background-color: #f94144 !important;
  padding: 5px !important;
}

.fa-love-btn path {
  font-size: 7px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.fa-smile-btn {
  width: 16px !important;
  height: 16px !important;
  border-radius: 99px !important;
  background-color: #f8961e !important;
  padding: 5px !important;
}

.fa-smile-btn path {
  font-size: 7px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.fa-angry-btn {
  width: 16px !important;
  height: 16px !important;
  border-radius: 99px !important;
  background-color: #4d908e !important;
  padding: 5px !important;
}

.fa-angry-btn path {
  font-size: 7px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.like-counts-box {
  border-radius: 3px !important;
  padding: 5px !important;
  background-color: #f9f9f9 !important;
}

.liked-post-text {
  color: blue !important;
}

.search-input-2 {
  border: 1px solid #eeeeee;
  border-radius: 6px !important;
  background-color: #ffff;
  height: auto;
  padding: 10px 54px 10px 8px;
  resize: unset !important;
}

.search-input-2:focus {
  border-color: #eeeeee !important;
}

// .search-input-3 {
//     border: 1px solid #EEEEEE;
//     border-radius: 100px !important;
//     background-color: #F9F9F9;
//     height: auto;
//     padding: 10px 54px 10px 15px;
// }

.search-input-3__control {
  border: 1px solid #eeeeee;
  border-radius: 100px !important;
  background-color: #f9f9f9;
  height: auto;
  padding: 10px 54px 10px 15px;
}

// .search-input-3:focus {
//     border-color: #EEEEEE !important;
// }

.search-input-3__control:focus {
  border-color: #eeeeee !important;
}

.btn-load-comments {
  background-color: #ffffff !important;
  color: #660c60 !important;
  // border-radius: 3px !important;
  border: none !important;
}

.nested-divider {
  color: #333333 !important;
}

.fa-like-btn-2 {
  width: 9px !important;
  height: 9px !important;
  border-radius: 99px !important;
  background-color: #1d3557 !important;
  padding: 3px !important;
}

.fa-like-btn-2 path {
  font-size: 9px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.fa-love-btn-2 {
  width: 9px !important;
  height: 9px !important;
  border-radius: 99px !important;
  background-color: #f94144 !important;
  padding: 3px !important;
}

.fa-love-btn-2 path {
  font-size: 9px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.fa-smile-btn-2 {
  width: 9px !important;
  height: 9px !important;
  border-radius: 99px !important;
  background-color: #f8961e !important;
  padding: 3px !important;
}

.fa-smile-btn-2 path {
  font-size: 9px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.fa-angry-btn-2 {
  width: 9px !important;
  height: 9px !important;
  border-radius: 99px !important;
  background-color: #4d908e !important;
  padding: 3px !important;
}

.fa-angry-btn-2 path {
  font-size: 9px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.author-avtar-creator-2 {
  height: auto !important;
  width: 25px !important;
  min-height: 25px;
}

.author-avtar-creator-2 img {
  height: 25px;
  width: 25px;
}

.reaction-options {
  display: flex;
  gap: 10px;
}

.reaction-icon {
  font-size: 24px;
  cursor: pointer;
}

.reaction-icon-2 {
  font-size: 12px !important;
  cursor: pointer;
}

.reaction-icon.like {
  color: #0073b1;
}

.reaction-icon.love {
  color: #e0245e;
}

.reaction-icon.smile {
  color: #f7b928;
}

.reaction-icon.angry {
  color: #d9534f;
}

.love-post-text {
  color: #f94144 !important;
}

.smile-post-text {
  color: #f8961e !important;
}

.angry-post-text {
  color: #4d908e !important;
}

.author-avtar-creator-4 {
  height: auto !important;
  width: 30px !important;
  min-height: 30px;
  font-size: 12px !important;
}

.suggestion {
  display: flex;
  align-items: center;
  padding: 5px;
}

.suggestionFocused {
  background-color: #ddd;
}

.suggestionPhoto {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.upto-date-divider {
  padding: 27px 0 0 0;
}

.upto-date-box-div {
  height: 60px;
  width: 60px;
  background-color: "#fffff";
  background: white;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-comments {
  width: 100%;
  padding: 7px 7px;
  border: none !important;
  height: 40px;
}

.input-comments__control {
  // height: 40px;
}

.input-comments__highlighter {
  // height: 40px;
  // margin: -24px 0 0 0;
}

.input-comments .input-comments__control textarea {
  border-radius: 100px !important;
  top: -4px !important;
  border: 1px solid #eeeeee !important;
}

.input-comments .input-comments__control input {
  border-radius: 6px !important;
  border: 1px solid #eeeeee !important;
  background-color: #f7f7f7 !important;
  // padding: 15px !important;
  // height: 40px;
  top: -10px;
}

.input-comments .input-comments__control textarea:focus-visible {
  outline: none !important;
}

.input-comments .input-comments__control input:focus-visible {
  outline: none !important;
}

.bold {
  font-weight: bold;
}

.semiBold {
  font-weight: 500;
  /* Semi-bold */
}

.list-class-div-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.list-class-div-1 img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.people-card-wrapper-3 {
  padding: 10px;
}

.people-card-wrapper-3 .MuiCardContent-root {
  padding: 10px !important;
}

.highlight-model-title-div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  padding: 16px 16px 10px 16px !important;
}

.seen-all-text {
  font-size: 12px;
  font-weight: 400;
  color: #959595;
}

.serach-box .search-btn-dash-2 {
  position: absolute;
  margin: 5px !important;
  bottom: 0px;
  right: 0px;
}

.serach-box .search-btn-dash-4 {
  // position: absolute !important;
  // margin: 5px !important;
  // bottom: 1px;
  // right: 1px;
}

.serach-box .search-btn-dash-5 {
  // position: absolute !important;
  // margin: 5px 18px !important;
  // margin: 5px 18px !important;
  // bottom: 1px;
  // right: 0;
}

.input-grp-chat {
  width: 100% !important;
}

.color-inherit {
  color: inherit !important;
}

.body-content-scroll {
  height: 380px;
  overflow-y: auto;
  padding: 16px 0;
}

.update-type-post-card {
  height: 154px;
  padding: 30px;
  margin: -92px 0 46px 0;
}

.update-type-post-card-content {
  padding: 0 !important;
}

.update-avtar-creator {
  height: 40px !important;
  width: 40px !important;
}

.post-date-label {
  font-size: 12px;
  font-weight: 500;
  color: #959595;
}

.edit-post-icon-btn {
  color: #333333 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 4px !important;
}

.post-posted-label {
  font-size: 12px !important;
}

.author-avtar-creator-post {
  height: 24px !important;
  width: 24px !important;
  font-size: 14px !important;
}

.card-content-box {
  display: flex;
  padding: 20px;
}

.posted-date-icon {
  font-size: 12px;
  font-weight: 400;
  color: #660c60;
}

.posted-date-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000000;
}

.categoty-label-chip {
  height: auto !important;
  background-color: #f9f9f9 !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
}

.categoty-label-chip span {
  font-weight: 500 !important;
  font-size: 9px !important;
  line-height: 10px !important;
  color: #959595;
  /* opacity: 65%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px !important;
}

.post-ack-box {
  padding: 0px 20px 20px 20px;
}

.comment-user-name {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #333333 !important;
  line-height: 18px !important;
}

.add-dot {
  position: relative;
}

.add-dot::before,
.add-dot::after {
  content: "";
  /* Ensures the pseudo-elements are visible */
  position: absolute;
  /* Position relative to the parent */
  top: 50%;
  /* Adjust vertically */
  transform: translateY(-50%);
  /* Center vertically */
  width: 4px;
  /* Example size of the dot */
  height: 4px;
  /* Example size of the dot */
  border-radius: 50%;
  /* Makes it a circle */
  background-color: #333333;
  left: 0;
  opacity: 80%;
  /* Example color of the dot */
}

// .add-dot::before {
//     left: 3px;
//     /* Adjust position before the text */
// }

// .add-dot::after {
//     right: -12px;
//     /* Adjust position after the text */
// }

.insight-img-box {
  width: 80px;
  height: 80px;
  // border-radius: 1px;
  // border: 1px solid #EEEEEE;
}

.insight-content-name {
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14.52px !important;
  color: #660c60 !important;
}

.insight-content-user-name {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #333333 !important;
}

.link-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.link-url {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #333333 !important;
  opacity: 50% !important;
}

.update-new-box-media {
  min-height: 100px;
  max-height: 100%;
  // border: 2px dashed;
  display: flex;
  align-items: center;
}

.border-media-upload-box {
  border: 2px dashed #ddd;
}

.add-more-images {
  color: #333333 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.upload-extra-image-grid {
  min-height: 144px;
  max-height: 100%;
}

.dashboard-title {
  font-size: 22px !important;
  line-height: 38px;
}

// reduce the font size in smaller screen

@media (max-width: 481px) {
  .dashboard-title {
    font-size: 18px !important;
    line-height: 30px;
  }

  .post-img {
    width: 100% !important;
    max-height: 150px !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .content-div-body {
    width: 100% !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: auto !important;
    max-height: 150px !important;
  }
}

.type-list-box {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 999px;
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.type-icon-box {
  font-size: 12px !important;
  font-weight: 900 !important;
  color: #333333 !important;
}

.type-name-box {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #333333 !important;
  width: max-content;
}

/* Highlight the selected type */
.type-list-box.selected {
  border: 1px solid #660c60;
  color: #660c60;
}

.type-list-box.selected .type-icon-box {
  color: #660c60 !important;
}

.type-list-box.selected .type-name-box {
  color: #660c60 !important;
}

.folder-tbl-cell-box {
  height: 20px;
  width: 20px;
}

.type-list-created-avatar-2 {
  border: 1px solid #eeeeee;
  height: 20px !important;
  width: 20px !important;
  font-size: 11px !important;
}
.action-menu .MuiPaper-root {
    min-width: 120px !important;
    border-radius: 6px !important;
    border: 1px solid #eeeeee;
    box-shadow: none !important;
    padding: 5px;
    background-color: #ffffff;
}

.action-menu-item {
    padding: 10px 15px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
}

.menu-item-icon {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #660c60;
}

.menu-item-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #95959595;
}
